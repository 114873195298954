import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnGoogleLoginComponent } from 'src/app/components/btns/btn-google-login/btn-google-login.component';
import { BtnFacebookLoginComponent } from 'src/app/components/btns/btn-facebook-login/btn-facebook-login.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from 'angularx-social-login';
import { LoaderModule } from '../../loader/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipeModule } from '../../pipe/pipe.module';
import { DataService } from 'src/app/services/data.service';

declare var configuracaoGlobal:any;

let dataService = new DataService();
let configuracao = dataService.getConfiguracao();

let chaveFacebook:any = null;   
let chaveGoogle:any  = null;  

if(configuracao != null){  
  if(configuracao.login_facebook_ativo){
    chaveFacebook = configuracao.login_facebook_chave;
  }
  if(configuracao.login_google_ativo){
    chaveGoogle = configuracao.login_google_chave;
  }
}else if(configuracaoGlobal != null){

  if(configuracaoGlobal.login_facebook_ativo){
    chaveFacebook = configuracaoGlobal.login_facebook_chave;
  }
  if(configuracaoGlobal.login_google_ativo){  
    chaveGoogle = configuracaoGlobal.login_google_chave;
  }

}

@NgModule({
  declarations: [
    BtnGoogleLoginComponent,
    BtnFacebookLoginComponent
  ],
  exports: [
    BtnGoogleLoginComponent,
    BtnFacebookLoginComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    LazyLoadImageModule,
    PipeModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              chaveGoogle
            )
          },{
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(chaveFacebook)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ]
})
export class BtnSocialLoginModule { }

