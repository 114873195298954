<div class="module-whatsapp" (click)="open()">
    <div class="content">
        <div class="header">
            <div class="icon-left">
                <i class="fa-regular fa-user"></i>
            </div>    
            <span>Fale com <br>um atendente</span> 
        </div>
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div> 
    </div> 
    <div class="content mobile">
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div>  
    </div>   
</div>    