<div class="produto-avaliacao-item">
      <div class="header">
            <div class="name-letter">
                  <span>{{firstLetter(data.nome)}}</span>
            </div>
            <div class="content-name">
                  <h5>
                        {{data.nome}}
                  </h5>
                  <span class="data-avaliacao">
                        <i class="fa-solid fa-calendar"></i>
                        {{data.data_avaliacao}}
                  </span>
            </div>
      </div>
      <div class="content">
            <div class="box-star-two">
                  <produto-avaliacao-star [notaServico]="data.nota" template="2"></produto-avaliacao-star>
            </div>
            <div class="descricao">
                  <h6>{{data.titulo}}</h6> 
                  <div class="content-descricao" #contentDescricao [ngClass]="{'more': more,'expand': expand}">
                        <article [innerHtml]="data.descricao"></article>
                  </div>
                  <button type="button" *ngIf="more" class="btn-more" (click)="_expand()">
                        <span>Leia mais</span>
                  </button>
            </div>
      </div>
</div>