import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'modulo-produto',
  templateUrl: './modulo-produto.component.html',
  styleUrls: ['./modulo-produto.component.scss']
})
export class ModuloProdutoComponent implements OnInit {

  @Input("data") data:any = null;
  public fotos:any = [];
  public produto:any = null;
  public textos:any = [];
  public caracteristica:any = [];
  public incluso:any = [];
  public naoIncluso:any = [];
  public dias:any = null;

  constructor(
    public dialog:MatDialog
  ){}
  /**
   * 
   * Open reserve
   * 
   */
  openReserve(){


  }
  /**
   * 
   * Set caracteristica
   * 
   */
  setCaracteristica(){

    for (let index = 0; index < this.caracteristica.length; index++) {
      
      if(this.caracteristica[index].tipo == 1){
        this.incluso.push(this.caracteristica[index]);
      }else{
        this.naoIncluso.push(this.caracteristica[index]);
      }
      
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
    this.produto        = this.data.data.produto;
    this.textos         = this.data.data.textos;
    this.fotos          = this.data.data.fotos;
    this.caracteristica = this.data.data.caracteristica;
    this.dias           = this.data.data.dias;

    this.setCaracteristica();

  }


}
