<div class="md-select-custom not-placeholder form-custom" (click)="_getData()">
      <label>{{label}}</label>
      <select [(ngModel)]="value" #select name="_value">
        <option [value]="value"  *ngIf="data.length == 0 && value != null">
          {{text}}
        </option>
        <option value="">----</option> 
        <option *ngFor="let d of data" [value]="d.value">
          {{d.text}}
        </option>
      </select>
      <div class="subscribe-message loader-message" *ngIf="loader">
        Carregando... Aguarde!
      </div>
      <div class="subscribe-message" *ngIf="response.status == 500">
        {{response.message}} 
      </div> 
</div> 
