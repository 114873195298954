import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dm-cart-resumo-steps',
  templateUrl: './dm-cart-resumo-steps.component.html',
  styleUrls: ['./dm-cart-resumo-steps.component.scss']
})
export class DmCartResumoStepsComponent implements OnInit {

  @Input("dataItem") dataItem      = null;
  @Input("servico") servico        = null;
  @Input("step") step              = 1;
  @Output("changeStep") changeStep = new EventEmitter();
  @Input("countTipo")   countTipo  = 1; 

  constructor(){}     
  
  /**
   * 
   * Seta o step
   * 
   * @param value    
   * 
   */
  setStep(value){

    this.changeStep.emit(value); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
