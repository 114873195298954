<header class="header {{classCustomTwo}}" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <modulo posicao="logo" class="logo-blue logo"></modulo>
            <modulo posicao="logo" class="logo-white logo" template="two"></modulo>
        </div>     
    </div>
    <div class="content-menu">
        <div class="linha"></div>
        <div class="container">  
            <partial-menu [classCustom]="classCustom"></partial-menu>
        </div>
        <div class="linha two"></div>
    </div>
</header>  
