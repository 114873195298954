<div class="produto-avaliacao" *ngIf="template == 'one'">
      <div class="title">
            <h3>Avaliações de Clientes</h3>
      </div>
      <div class="total">
            <h4>Avaliação Geral</h4>
            <div class="nota-resumo">
                  <strong>
                        {{data.nota}} / 5
                  </strong>
            </div>
            <ul>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 1">
                              <i class="fa-solid fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 2">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 2">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 3">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 3">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 4">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 4">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 5">
                              <i class="fa-solid fa-star"></i>
                        </div>  
                        <div class="icon gray" *ngIf="data.nota < 5">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
            </ul>
            <div class="info">
                  <span>Com base em </span> <strong>{{data.total}}</strong> <span> avaliações.</span>
            </div>
      </div>
      <div class="list-title">
            <h4>Últimas avaliações:</h4>
      </div>
      <div class="list">
            <div class="item" *ngFor="let l of data.lista">
                  <div class="box">
                        <div class="icon">  
                              <i class="fa-solid fa-user"></i>
                        </div>
                        <span class="name">{{l.nome}}</span>
                        <span class="date">
                              <i class="fa-solid fa-calendar"></i>
                              {{l.data_avaliacao}}
                        </span>
                  </div>
                  <div class="box box-content">
                        <h4>{{l.titulo}}</h4>    
                        <article [innerHtml]="l.descricao"></article>
                  </div>
                  <div class="box box-star">
                        <produto-avaliacao-star [notaServico]="l.nota" template="2"></produto-avaliacao-star>
                  </div>
            </div>
      </div>
</div>
<div class="produto-avaliacao" *ngIf="template == 'two'">
      <div class="title">
            <h3>Avaliações de Clientes</h3> 
      </div>
      <div class="total">
            <h4>Avaliação Geral</h4>
            <div class="nota-resumo">
                  <strong>
                        {{data.nota}} / 5
                  </strong>
            </div>
            <ul>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 1">
                              <i class="fa-solid fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 2">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 2">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 3">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 3">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 4">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 4">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 5">
                              <i class="fa-solid fa-star"></i>
                        </div>  
                        <div class="icon gray" *ngIf="data.nota < 5">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
            </ul>
            <div class="info">
                  <span>Com base em </span> <strong>{{data.total}}</strong> <span> avaliações.</span>
            </div>
      </div>
      <div class="list-title">
            <h4>Últimas avaliações:</h4>
      </div>
      <div class="list">
            <div class="avaliacoes slider-itens" #slider> 
                  <div class="item slider-item" *ngFor="let l of data.lista">
                        <produto-avaliacao-item [data]="l"></produto-avaliacao-item>
                  </div> 
            </div>
            <div class="swiper-button-next sw-btn" (click)="next()" *ngIf="(data.lista.length > 3 && width > 480)"> 
                  <img src="/images/icons/arrow-right.svg" alt="Arrow" />
            </div>
      </div>
      <div class="avaliacao-usuario" *ngIf="data.configuracao.ativar_avaliacao_usuario">
            <button type="button" class="btn-avaliacao" (click)="openModal()">
                  <i class="fa-solid fa-heart"></i>
                  <span>Faça a sua avaliação</span>
            </button>
      </div>
</div>