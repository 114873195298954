import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'texto-modal',
  templateUrl: './texto-modal.component.html',
  styleUrls: ['./texto-modal.component.scss']
})
export class TextoModalComponent implements OnInit {

  public title = null;
  public texto = null;

  constructor(
    public dialog: MatDialogRef<TextoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 

    this.title = this.data.title;
    this.texto = this.data.texto;

  }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
