import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboManagerHotelV2Component } from 'src/app/components/combos/combo-manager-hotel-v2/combo-manager-hotel-v2.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ComboManagerHotelV2Component
  ],
  exports: [
    ComboManagerHotelV2Component
  ],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class ComboHotelModule { }
