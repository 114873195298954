<div class="product-gallery">
      <div class="photos">
          <div class="row"> 
              <div class="col-md-4 col-custom" *ngFor="let p of photos;let i=index;">
                  <img [src]="p.imagem" [alt]="p.titulo"  width="100%" (click)="open(i)" />
              </div>  
          </div> 
      </div>    
</div> 
      
  