<div class="dm-cart-dados-voo form-custom">
  <div class="dm-cart-dados-voo-content">
    <form class="dm-form">  
      <div class="row">
        <div class="col-md-6">  
          <combo-aeroporto-v2 [value]="aeroporto" label="Aeroporto:" (changeValue)="changeAeroporto($event)" [text]="aeroporto"></combo-aeroporto-v2>      
        </div>   
        <div class="col-md-6">
          <combo-cia-aerea-v2 [text]="ciaText" [value]="ciaValue" (changeValue)="changeCia($event)"></combo-cia-aerea-v2>
        </div>  
      </div> 
      <div class="row"> 
        <div class="col-md-6">
          <div class="input-numero">
            <label>Nº do Voo:</label>
            <input type="text" [(ngModel)]="voo" placeholder="***" name="voo" integer (input)="changeVoo()"/>
          </div> 
        </div> 
        <div class="col-md-6"> 
          <div class="input-numero">
            <label>Hora do Vôo:</label>   
            <input type="text" [(ngModel)]="hora" placeholder="HH:MI" name="hora" mask="99:99" (ngModelChange)="changeHora()"/>
          </div>
        </div>   
      </div>
      <div class="row" *ngIf="data != null"> 
        <div class="col-md-6">
          <div class="input-numero">
            <label>Data Partida:</label> 
            <div class="content-calendar">
              <input type="text" placeholder="DD/MM/YYYY" [(ngModel)]="data" name="data" mask="99/99/9999" (ngModelChange)="changeData()" #dateInput />
              <div class="icon" (click)="openCalendar()">
                <i class="fas fa-calendar-alt"></i>
              </div>  
            </div>  
          </div>
        </div> 
      </div>  
    </form>  
  </div>  
</div>
