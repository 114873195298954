<div class="dm-modal">
    <div class="dm-modal-header">
        <span>Fotos</span>
        <div class="icon" (click)="dialog.close()">
            <img src="images/icons/close.svg" alt="Close" width="25px" />
        </div>
    </div>
    <div class="dm-modal-content">
        <div class="navigate">
            <div class="navigate-item prev" (click)="prev()">
                <div class="content">
                  <img src="images/icons/prev-01.svg" alt="Prev" width="40px" />
                </div>
            </div> 
            <div class="navigate-item next" (click)="next()">
                <div class="content">
                  <img src="/images/icons/next-01.svg" alt="Next" width="40px" />
                </div>
            </div>    
        </div> 
        <div class="photos">
            <div class="photo animated fadeIn" *ngFor="let f of fotos;let i=index;" [ngClass]="{'show': i == index}">  
                <img [src]="f.imagem" [alt]="f.titulo" width="100%" />
            </div>
        </div>
    </div>
</div>
