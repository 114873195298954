<div class="dm-modal produto-video-modal">
    <div class="dm-modal-title">
        <div class="close" (click)="_close()">
            <i class="far fa-times-circle"></i> 
        </div>    
    </div>
    <div class="dm-modal-content">    
        <iframe width="100%" height="400px" [src]="url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
    </div>    
</div>    