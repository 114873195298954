import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartDataServicoComponent } from 'src/app/components/carrinho/dm-cart-data-servico/dm-cart-data-servico.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    DmCartDataServicoComponent
  ],
  exports: [
    DmCartDataServicoComponent
  ],
  imports: [
    LazyLoadImageModule,
    LoaderModule,
    CommonModule
  ]
})
export class CartDataServicoModule { }
