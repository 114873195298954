<div class="module module-destination paralax" style='background-image: url("/images/modulo/paralax/{{data.image}}");'>
      <div class="module-title">
          <span class="wow slideInUp subtitle" data-wow-delay="0.2s">
              {{data.subtitulo}}
          </span>    
          <span class="wow slideInDown title" data-wow-delay="0.4s">
              {{data.titulo}}
          </span>
          <span class="wow slideInUp subtitle info" data-wow-delay="0.6s" [innerHtml]="data.texto"></span>   
      </div>    
      <div class="module-content">
          <div class="container">    
              <div class="content-button" *ngIf="data.botao_compra_ativo">
                <btn-reserva *ngIf="data.botao_compra_ativo" [produto]="produto" [id]="data.produto_id" class="wow slideInUp"></btn-reserva>
              </div>  
          </div>    
      </div> 
      <div class="bg"></div>   
  </div>    