import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';
import { ServicoService } from 'src/app/services/servico.service';
import { ApiService } from '../../../services/api.service';

declare var $: any;

@Component({
  selector: 'dm-cart-data-servico',
  templateUrl: './dm-cart-data-servico.component.html',
  styleUrls: ['./dm-cart-data-servico.component.scss']
})
export class DmCartDataServicoComponent implements OnInit, AfterViewInit{

  @ViewChild('datepicker') datepicker;
  @Input("value") value                               = null;
  @Input("idServico") idServico                       = null;
  @Input("idTipoServico") idTipoServico               = null;
  @Input("idMacroRegiao") idMacroRegiao               = null; 
  @Input("idTipoTransporte") idTipoTransporte         = null; 
  @Input("idZonaRegiaoHotel") idZonaRegiaoHotel       = null; 
  @Input("idCidade") idCidade                         = null;  
  @Input("dataInicio") dataInicio                     = null;
  @Input("dataFim") dataFim                           = null;
  @Output("changeValue") changeValue                  = new EventEmitter();  
  public loader                                       = false;
  @Input("disponibilidadeArray") disponibilidadeArray = null;
  public mes = null;
  public ano = null;
  public messageLoader = "";
  @Input("frequenciaApartirDe") frequenciaApartirDe   = null;
  public message        = null;
  public minDate:any    = new Date();
  @Input("dataServico") dataServico = null;
  public setDate = true;
  @Input("snr") snr:any = 0;
  @Input("adt") adt:any = 0;

  constructor(
    private api: ApiService,
    private _servico: ServicoService
  ){ 

  }
  /**
  * 
  * Cria o calendário
  * 
  */
 create(){
   
  let self = this;

  $(this.datepicker.nativeElement).datepicker({
     dateFormat: "dd/mm/yy",
     minDate: new Date(),
     changeMonth: true,
     defaultDate: this.value,
     changeYear: false, 
     monthNamesShort: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
     dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
     monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
     beforeShowDay: this.habilitarDates,
     onSelect: (value)=>{
      
      let data   = $(this.datepicker.nativeElement).val();
      this.value = data;
      
      this.changeValue.emit({
        data: data,
        disponibilidadeArray: this.disponibilidadeArray,
        init: false
      });
      
      setTimeout(() => {
        self.preencheValoresCalendario(self); 
      });
           

     },
     onChangeMonthYear: (ano,mes) =>{
 
      this.mes = mes;
      this.ano = ano;
      this.setDate = false;

      this.getData();

     } 

   });
   setTimeout(() => {
    self.preencheValoresCalendario(self); 
   });
   
 }
  /**
  * 
  * Habilita somente as datas disponíveis
  * 
  */
  habilitarDates = (d: Date) => {
    
    let dataDia       = null;
    
    if(dataDia = this.hasDate(d)){
      
      let idTipoServico = parseInt(dataDia.tipo_contratacao);
      let valor         = idTipoServico == 2 ? dataDia.valor_servico.toFixed(2).toString() : dataDia.valor_adt.toFixed(2).toString();   
    
      return [true,"date-td date-td-valor",valor]; 

    }else{
      return [false,"date-td",null];
    }

  }
  /**
  * 
  * 
  * 
  */
 hasDate = (d: Date): any =>{

    let result:boolean = false
    let dataS          = null
    let dataOut        = null
    let datas          = this.disponibilidadeArray;
    let response:any   = false;
    let _response = {
      valor_adt: 0,
      valor_chd: 0,
      valor_snr: 0,
      valor_servico: 0,
      codigo_moeda: 0,
      tipo_contratacao: 0
    } 

    if(typeof(datas) != "string"){
      
      if(datas.length > 0){
        
        datas = typeof(datas.length) == "undefined" ? [datas] : datas; 

        let item             = datas[0];
        let valor_adt        = this._servico.formatarTarifa(item.tarifaADT);
        let valor_chd        = this._servico.formatarTarifa(item.tarifaCHD);
        let valor_snr        = this._servico.formatarTarifa(item.tarifaSNR);
        let valor_servico    = this._servico.formatarTarifa(item.tarifaServico);
        let tipo_contratacao = item.tipoContratacaoTarifa;
        let codigo_moeda     = item.codigoMoeda;

        _response = {
          valor_adt: valor_adt,
          valor_chd: valor_chd,
          valor_snr: valor_snr,
          valor_servico: valor_servico,
          codigo_moeda: codigo_moeda,
          tipo_contratacao: tipo_contratacao
        } 
      
      }

    }else{
      datas = [];
    }

    let self = this;

    datas.forEach(element => {
        
      dataS   = element.dia.split("/"); 
      dataOut = new Date(parseInt(dataS[2]),(parseInt(dataS[1])-1),parseInt(dataS[0]));
       

        if(dataOut.getDate() == d.getDate() && dataOut.getMonth() == d.getMonth() && dataOut.getFullYear() == d.getFullYear()
          ){

            let m = (dataOut.getMonth()+1) < 10 ? "0"+(dataOut.getMonth()+1) : (dataOut.getMonth()+1);
            let _d = dataOut.getDate()+"/"+m+"/"+dataOut.getFullYear();
            
            let tarifas:any = self._servico.getTarifasByDate2(
              _d,
              self.idZonaRegiaoHotel,
              self.idCidade,
              datas
            );

            response = tarifas;   
            result = true;

        }

        if(result == true){

          if(response.valor_adt == 0 && response.valor_servico == 0){

            response = _response;

          }

        }

    });
    return response; 

  }
  /**
   * 
   * Retorna a disponibilidade
   * 
   */
  getData(){

    try{

      this.loader = true;
      this.messageLoader = "Carregando disponibilidade.";

      setTimeout(() => {
        
        this.api.manage().disponibilidade(
          this.idServico,
          this.idTipoServico,
          this.idMacroRegiao,
          this.idTipoTransporte,
          this.mes,
          this.ano,
          (this.adt+this.snr)
        ).subscribe(response => {

          this.loader = false;

          if(response.status == 1){
            
            this.disponibilidadeArray = response.data;
            this.create(); 
            $(this.datepicker.nativeElement).datepicker("refresh");
            
            setTimeout(() => {

              if(this.disponibilidadeArray.length > 0){
                //$(this.datepicker.nativeElement).datepicker("setDate",this.disponibilidadeArray[0].dia);
              }
              if(this.frequenciaApartirDe != null){
                $(this.datepicker.nativeElement).datepicker("minDate",this.frequenciaApartirDe);
              }
              if(this.value != null){
                this.changeValue.emit({
                  data: this.value,
                  disponibilidadeArray: this.disponibilidadeArray,
                  init: true
                });
                //$(this.datepicker.nativeElement).datepicker("setDate",this.value);
              }

            },200);
            setTimeout(() => {
              this.preencheValoresCalendario();
            },500);
            
          }

        },(response) => {

          this.loader = false;

        });

      },200);

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Preenche valores
   * 
   */
  preencheValoresCalendario(self=this){

    $(self.datepicker.nativeElement).find(".date-td-valor").map(function(){
      let valor = this.title;
      $(this).append("<span class='valor'>"+valor+"</span>"); 
    });

  }
  /**
   * 
   * 
   * 
   */
  formatarData(data){

    if(data != null){

      let d   = data.split("/");
      let dia = d[0];
      let mes = d[1];
      let ano = d[2];

      this.mes     = mes;
      this.ano     = ano; 
      this.minDate = new Date(dia+"-"+mes+"-"+ano);

    }


  }
  /***
   * 
   * Set mes
   * 
   */
  setMes(){

    if(this.value != null){
      
      let d = this.value.split("/");

      this.mes = d[1];
      this.ano = d[2];

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    this.formatarData(this.frequenciaApartirDe);
    this.setMes();

  }
  /**
   * 
   * Iniciliza as Funções após carregar a VIEW
   * 
   */
  ngAfterViewInit(){

    if(this.disponibilidadeArray != null && this.value != null){
      
      this.create();
      $(this.datepicker.nativeElement).datepicker("setDate",this.value);
    
    }else{

      this.loader = true;
      setTimeout(() => {
        this.getData();
      },200);  

    }
     
  }

}
