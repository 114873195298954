<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">  
    <modulo-logo [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'whatsapp'">   
    <modulo-whatsapp [data]="m" [classCustom]="classCustom" [template]="template"></modulo-whatsapp>  
  </div>
  <div *ngSwitchCase="'rede-social'">   
    <modulo-rede-social [data]="m" [classCustom]="classCustom" [template]="template"></modulo-rede-social>  
  </div>
  <div *ngSwitchCase="'endereco'">     
    <modulo-endereco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-endereco>  
  </div>
  <div *ngSwitchCase="'unidades'">      
    <modulo-unidades [data]="m" [classCustom]="classCustom" [template]="template"></modulo-unidades> 
  </div>
  <div *ngSwitchCase="'banner-slider'">  
    <banner-slider-slick [data]="m" [classCustom]="classCustom" [template]="template"></banner-slider-slick> 
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [classCustom]="classCustom" [template]="template"></modulo-newsletter> 
  </div>
  <div *ngSwitchCase="'contato'">   
    <modulo-contato [data]="m" [classCustom]="classCustom" [template]="template"></modulo-contato> 
  </div> 
  <div *ngSwitchCase="'fale-conosco'">  
    <modulo-fale-conosco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-fale-conosco> 
  </div>
  <div *ngSwitchCase="'destinos'">       
    <modulo-destinos [data]="m" [template]="template" [classCustom]="classCustom"></modulo-destinos> 
  </div>
  <div *ngSwitchCase="'produto-destaque-1'">       
    <modulo-produto-destaque-one [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-one> 
  </div>
  <div *ngSwitchCase="'produto-destaque-2'">       
    <modulo-produto-destaque-two [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-two> 
  </div>
  <div *ngSwitchCase="'produto-experiencia'">       
    <modulo-produto-experiencia [data]="m" [template]="template"></modulo-produto-experiencia> 
  </div>
  <div *ngSwitchCase="'produto-destaque-3'">       
    <modulo-produto-destaque-three [data]="m" [template]="template"></modulo-produto-destaque-three> 
  </div>
  <div *ngSwitchCase="'destaque-blog'">          
    <blog-destaque [data]="m" [template]="template"></blog-destaque> 
  </div>
  <div *ngSwitchCase="'logo-footer'"> 
    <modulo-logo-footer [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo-footer> 
  </div>
  <div *ngSwitchCase="'contato-suporte'"> 
    <contato-suporte-modulo [data]="m" [classCustom]="classCustom" [template]="template"></contato-suporte-modulo> 
  </div>
  <div *ngSwitchCase="'destinos-menu'"> 
    <destinos-menu-modulo [data]="m" [classCustom]="classCustom" [template]="template"></destinos-menu-modulo> 
  </div>
  <div *ngSwitchCase="'trip-advisor'"> 
    <trip-advisor-modulo [data]="m" [classCustom]="classCustom" [template]="template"></trip-advisor-modulo> 
  </div>
</div>
  
  
  