import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuarioLoginModal } from '../../usuario/usuario-login-modal/usuario-login-modal.component';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'btn-usuario-login',
  templateUrl: './btn-usuario-login.component.html',
  styleUrls: ['./btn-usuario-login.component.scss']
})
export class BtnUsuarioLoginComponent implements OnInit {
  
  @Input("usuarioService") usuarioService = null;
  @Input("template") template             = null;   
  public usuario                          = null; 
  public nome                             = null;
  public lp                               = null;

  constructor( 
    private storage: StorageService,  
    private router: Router,
    private app: AppService,
    private dialog: MatDialog,
    private data: DataService
  ){

    this.lp = this.data.getLp();

  }
  /**
   * 
   * Open modal
   * 
   */
  _open(){

    this.dialog.open(
      UsuarioLoginModal,
      {
        width: "500px",
        data: {
          content: 1, 
          usuarioService: this.usuarioService
        }
      }
    );

  }
  /**
   * 
   * @param value 
   * 
   * 
   */
  toRoute(value){

    this.router.navigateByUrl(value);

  }
    /**
   * 
   * On Data
   * 
   */
  onData(){

  
    this.usuarioService.emitUsuarioLogin.subscribe((usuario) => {
       
      try{
        
        if(usuario === "null" || usuario === null || usuario === "" || typeof(usuario) == "undefined" || typeof(usuario) == "function"){
          this.usuario = null;
        }else{ 
          this.usuario = usuario;  
          this.setNome(); 
        }  
        
      }catch(e){
        
      }
      
    });

  }
  /**
   * 
   * Set nome
   * 
   */
  setNome(){

    if(this.usuario != null){

      let data = this.usuario.nome.split(" ");

      this.nome = data[0]; 

    }

  }
  /**
   * 
   * Seta o usuário
   * 
   */
  setUsuario(){

    this.usuario = this.storage.getUsuario();
    this.setNome();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.onData();
    this.setUsuario();
  }

}
