<div class="app-content" *ngIf="ativo"> 
    <router-outlet></router-outlet>   
    <partial-footer></partial-footer>
</div>   
<div class="app-content site-desativado" *ngIf="!ativo"> 
    <div class="content">
        <span class="title">Estamos em manutenção</span>
        <span class="subtitle">E breve traremos novidades.</span>
    </div>
</div>


