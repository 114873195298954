import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'produto-avaliacao-modal',
  templateUrl: './produto-avaliacao-modal.component.html',
  styleUrls: ['./produto-avaliacao-modal.component.scss']
})
export class ProdutoAvaliacaoModalComponent implements OnInit {

  public loader       = false;
  public dataItem:any = {};

  constructor(
    public dialog: MatDialogRef<ProdutoAvaliacaoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    public app: AppService,
    public api: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){

    this.initDataItem();

  }

  /** 
   * 
   * Init 
   * 
   * 
   **/ 
  initDataItem(){

    this.dataItem = {
      nome: "",
      titulo: "",
      descricao: "",  
      nota: null,
      produto_id: this.data.produto.id,
      ativo: false,
      destaque: false,
      produto: this.data.produto.nome
    }

  }
  /**
   * 
   * Select
   * 
   */
  _select(nota:any){

    this.dataItem.nota = nota;

  }
  /**
   * 
   * Store
   * 
   */
  store(){

    try{

      this.loader = true;

      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token:any):any => {

        this.dataItem.token = token;

        this.api.avaliacao().avaliacaoProduto().store(this.dataItem).subscribe((response:any) => {

          this.loader = false;   

          if(response.status == 1){

            this.app.info("Recebemos a sua avaliação. Muito Obrigado :)");
            this.dialog.close();

          }else{

            let error  = this.api.formatError(response);
            this.app.info(error.message);

          }

        },(response:any) => {

          this.loader = false;
          let error  = this.api.formatError(response);

          this.app.info(error.message);
          
        });

      },(response:any):any => {

        this.loader = false; 
        let messages        = "Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.";
        this.app.info(messages,"danger"); 

      });


    }catch(e:any){

      this.loader = false;
      this.app.info(e.message);

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    
  }


}
