import { Component, OnInit } from '@angular/core';
import { TextoModalComponent } from '../modal/texto-modal/texto-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'partial-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public configuracao = null;

  constructor(
    private dialog: MatDialog,
    private data: DataService
  ) { 

    this.configuracao = this.data.getConfiguracao();

  }

  /**
   * 
   * Open politicas
   * 
   * 
   */
  openPoliticas(){

    this.dialog.open(TextoModalComponent,{
      width: "800px",
      data: {
        title: "Políticas de Privacidade",
        texto: this.configuracao.politica_privacidade_texto
      }
    });
    return false;

  }
  /**
   * 
   * Open politicas
   * 
   * 
   */
  openTermos(){

    this.dialog.open(TextoModalComponent,{
      width: "800px",
      data: {
        title: "Termos de Uso",
        texto: this.configuracao.termo_uso_texto
      }
    });
    return false;

  }
  ngOnInit():void{} 

}
