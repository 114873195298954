<div class="module module-products">
  <div class="module-title">
      <div class="container">
          <div class="content-title" *ngIf="data.mostrar_titulo">
              <div class="box">
                  <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
              </div>
              <div class="box" *ngIf="data.mostrar_subtitulo">
                  <h3 class="wow slideInRight" data-wow-delay="0.8s">{{data.subtitulo}}</h3>
              </div>
          </div>
      </div>    
  </div>
  <div class="module-content">
      <div class="container">
          <div class="products"> 
              <div class="row">  
                  <div class="col-md-3" *ngFor="let p of produtos">
                      <produto-item [data]="p"></produto-item>
                  </div> 
              </div>     
          </div>
      </div>
  </div>   
</div>    
