<div class="modulos-destaque">
      <div *ngFor="let m of modulos;let last=lats;" [ngSwitch]="m.tag">
            <div *ngSwitchCase="'avaliacao'">   
                  <modulo-avaliacao [data]="m"></modulo-avaliacao>
            </div>
            <div *ngSwitchCase="'itens-destaque'">   
                  <modulo-itens-destaque [data]="m"></modulo-itens-destaque>
            </div>
            <div *ngSwitchCase="'duvidas-frequentes'">   
                  <modulo-duvida-frequente [data]="m"></modulo-duvida-frequente>
            </div>
            <div *ngSwitchCase="'paralax'">   
                  <modulo-paralax [data]="m"></modulo-paralax>
            </div>
            <div *ngSwitchCase="'produto'">   
                  <modulo-produto [data]="m"></modulo-produto>
            </div>
            <div *ngSwitchCase="'produto-destaque'">   
                  <modulo-produto-destaques [data]="m"></modulo-produto-destaques>
            </div>
      </div>
</div>       
