import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboManagerDestinoComponent } from 'src/app/components/combos/combo-manager-destino/combo-manager-destino.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ComboManagerDestinoComponent
  ],
  exports: [
    ComboManagerDestinoComponent
  ],
  imports: [
    FormsModule, 
    CommonModule
  ]
})
export class ComboDestinoModule { }
