<div class="dm-modal">
  <div class="dm-modal-header">
      <span>Reservar</span>
      <div class="icon" (click)="dialog.close()">
          <img src="images/icons/close.svg" alt="Close" width="25px" />
      </div>
  </div>
  <div class="dm-modal-content">
    <dm-cart-add [dialog]="dialog" [cartService]="cartService" [emailSugestao]="emailSugestao" [idCart]="idCart" [email]="email" [horarios]="horarios" [servico]="servico" [categoria]="categoria" [configuracaoLoja]="configuracaoLoja" [macroRegiao]="macroRegiao"></dm-cart-add>
  </div>
</div> 
<loader *ngIf="loader"></loader>
