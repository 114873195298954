import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'produto-video-modal',
  templateUrl: './produto-video-modal.component.html',
  styleUrls: ['./produto-video-modal.component.scss']
})
export class ProdutoVideoModal implements OnInit {
  
  public url = null;

  constructor(
    public dialog: MatDialogRef<ProdutoVideoModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer
  ){}

  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close(); 

  }
  /**
   * 
   * Seta o Video
   * 
   */
  setVideo(){

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video);

  } 
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.setVideo();
  }

}
