import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'dm-cart-dados-voo',
  templateUrl: './dm-cart-dados-voo.component.html',
  styleUrls: ['./dm-cart-dados-voo.component.scss']
})
export class DmCartDadosVooComponent implements OnInit,AfterViewInit {

  @Input("dataItem") dataItem:any             = {}; 
  @Input("voo") voo:any                       = null; 
  @Input("data") data:any                     = null; 
  @Input("hora") hora:any                     = null; 
  @Output("changeCiaAerea") _changeCiaAerea   = new EventEmitter();
  @Output("changeAeroporto") _changeAeroporto = new EventEmitter();
  @Input("ciaValue") ciaValue                 = null;
  @Input("ciaText") ciaText                   = null;
  @Input("aeroporto") aeroporto     = null;
  @Output("changeVoo") _changeVoo             = new EventEmitter();
  @Output("changeData") _changeData           = new EventEmitter();
  @ViewChild("dateInput") dateInput:ElementRef;
  @Input("horaChegada") hora_chegada:any       = null; 
  @Input("horaPartida") hora_partida:any       = null;  
  @Output("changeHoraChegada") _changeHoraChegada  = new EventEmitter();
  @Output("changeHoraPartida") _changeHoraPartida  = new EventEmitter();
  @Input("dataUtilizacao") data_utilizacao:any      = null; 
  @Output("changeHora") _changeHora            = new EventEmitter();

  constructor(){ 
    
  }
  /**
   * 
   * Change value
   * 
   */
  changeCia(data){
    this._changeCiaAerea.emit(data);
  }
  /**
   * 
   * Change value
   * 
   */
  changeAeroporto(data){
    this._changeAeroporto.emit(data); 
  }
  /**
   * 
   * Change VOO
   * 
   */
  changeVoo(){

    this._changeVoo.emit(this.voo)

  }
  /**
   * 
   * Change Data
   * 
   */
   changeHora(){
 
    this._changeHora.emit(this.hora);  

  }
  /**
   * 
   * Change Data
   * 
   */
  changeData(){

    this._changeData.emit(this.data); 

  }
  /**
   * 
   * Change Data
   * 
   */
  changeHoraChegada(){
 
    this._changeHoraChegada.emit(this.hora_chegada);  

  }
  /**
   * 
   * Change Data
   * 
   */
  changeHoraPartida(){

    this._changeHoraPartida.emit(this.hora_partida); 

  }
  /**
  * 
  * Cria o calendário
  * 
  */
  create(){
   
    let self = this; 

    if(typeof(this.dateInput.nativeElement) != "undefined"){

      $(this.dateInput.nativeElement).datepicker({
        dateFormat: "dd/mm/yy", 
        minDate: this.data_utilizacao,
        changeMonth: false,
        changeYear: false, 
        dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
        monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        onSelect: (value)=>{ 
          
          let data   = $(this.dateInput.nativeElement).val();
          self.data = data;
          self.changeData();

        }

      });  
    
    }

  }
  /**
   * 
   * Iniciliza Funções
   * 
   * 
   */
  ngOnInit(){
    
  }
  ngAfterViewInit(): void {
    this.create();
  }

}
