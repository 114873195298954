<div class="module-rating" id="rating">  
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2 class="wow slideInUp">{{data.titulo}}</h2>
              <h3 class="wow slideInDown" [innerHtml]="data.subtitulo"></h3>
          </div>
      </div>
      <div class="module-content">
          <div class="container">
              <div class="items slider-items slick-padding-mobile" #slider>    
                  <div class="rating-item slider-item" *ngFor="let p of data.avaliacoes">
                      <div class="photo">
                          <img [src]="p.foto" [alt]="p.nome" />
                      </div>
                      <h4>{{p.nome}}</h4>
                      <div class="date">
                          <i class="fa-solid fa-calendar-days"></i>
                          <span>{{p.data_avaliacao}}</span>
                      </div>
                      <div class="rating-star">
                          <div class="item" *ngIf="p.nota >= 1">
                              <i class="fa-solid fa-star"></i>
                          </div>
                          <div class="item" *ngIf="p.nota >= 2">
                              <i class="fa-solid fa-star"></i>
                          </div>
                          <div class="item" *ngIf="p.nota >= 3">
                              <i class="fa-solid fa-star"></i>
                          </div>
                          <div class="item" *ngIf="p.nota >= 4">
                              <i class="fa-solid fa-star"></i>
                          </div>
                          <div class="item" *ngIf="p.nota >= 5">
                              <i class="fa-solid fa-star"></i>
                          </div>
                      </div>
                      <div class="content-description" *ngIf="p.tipo_conteudo == 1">
                          <span class="aspas">“</span>
                          <article class="description" [innerHtml]="p.descricao"></article>
                      </div>
                      <div class="content-description" *ngIf="p.tipo_conteudo == 2">
                        <iframe width="100%" height="400px" [src]="getUrl(p.url_video)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                  </div>
              </div>
              <div class="navigate-items">
                  <div class="prev" (click)="prev()">
                      <img src="/images/icons/prev-01.svg" alt="Prev" width="30px" />
                  </div>
                  <div class="next" (click)="next()">
                      <img src="/images/icons/next-01.svg" alt="Next" width="30px" />
                  </div>
              </div>
          </div>
      </div>
      <div class="content-button" *ngIf="data.botao_compra_ativo">
        <div class="container">
            <btn-reserva [produto]="produto" [id]="produto.id" class="btn-reserva-content  slideInLeft"></btn-reserva>
        </div>
      </div>
</div>
