<div class="module-questions" id="faq">
      <div class="module-title">
          <div class="container">
              <h2 class="wow slideInUp">{{data.titulo}}</h2>
          </div>
      </div>
      <div class="module-content">
          <div class="container">
              <div class="items">
                  <div class="item" *ngFor="let i of data.data">
                      <div class="item-header" (click)="_show(i)" [ngClass]="{'show': i.show}">
                          <span>{{i.titulo}}</span>
                          <i class="fa-solid fa-chevron-down"></i>
                      </div>
                      <div class="item-content" *ngIf="i.show"> 
                          <article [innerHtml]="i.texto"></article>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>  