import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartPassageirosQtdComponent } from 'src/app/components/carrinho/dm-cart-passageiros-qtd/dm-cart-passageiros-qtd.component';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    DmCartPassageirosQtdComponent
  ],
  exports: [
    DmCartPassageirosQtdComponent  
  ],
  imports: [
    FormsModule,
    CommonModule,
    PipeModule  
  ]
})  
export class CartPassageirosQtdModule{}
