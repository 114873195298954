<div class="dm-modal">
      <div class="dm-modal-title">
            <span>Avaliação</span>
            <div class="dm-close" (click)="dialog.close()">
                  <i class="far fa-times-circle"></i> 
            </div>    
      </div>    
      <div class="dm-modal-content form-custom">
            <h3><strong>Avalie o produto</strong> {{dataItem.produto}}</h3> 
            <div class="row">
                  <div class="col-md-12">
                        <label>Seu nome:</label>
                        <input type="text" placeholder="Informe seu nome aqui..." [(ngModel)]="dataItem.nome" name="nome" />
                  </div>
            </div>
            <div class="row">
                  <div class="col-md-12">
                        <label>Título da sua avaliação:</label>
                        <input type="text" placeholder="Descreva em uma frase a sua experiência..." [(ngModel)]="dataItem.titulo" name="titulo" />
                  </div>
            </div>
            <div class="nota">
                  <div class="row">
                        <div class="col-md-12">
                              <h3 class="title-custom">Dê a nota para a avaliação:</h3>
                              <div class="content-list">
                                    <ul>
                                          <li>
                                                <div class="number" (click)="_select(5)" [ngClass]="{'active': dataItem.nota == 5}">
                                                      <span>5</span>
                                                </div>
                                                <div class="text">
                                                      <span>Excelente</span>
                                                </div>
                                          </li>
                                          <li>
                                                <div class="number" (click)="_select(4)" [ngClass]="{'active': dataItem.nota == 4}">
                                                      <span>4</span>
                                                </div>
                                                <div class="text">
                                                      <span>Bom</span>
                                                </div>
                                          </li>
                                          <li>  
                                                <div class="number" (click)="_select(3)" [ngClass]="{'active': dataItem.nota == 3}">
                                                      <span>3</span>
                                                </div>
                                                <div class="text">
                                                      <span>Regular</span>
                                                </div>
                                          </li>
                                          <li>
                                                <div class="number" (click)="_select(2)" [ngClass]="{'active': dataItem.nota == 2}">
                                                      <span>2</span>
                                                </div>
                                                <div class="text">
                                                      <span>Ruim</span>
                                                </div>
                                          </li>
                                          <li> 
                                                <div class="number" (click)="_select(1)" [ngClass]="{'active': dataItem.nota == 1}">
                                                      <span>1</span>
                                                </div>
                                                <div class="text">
                                                      <span>Ruim</span>
                                                </div>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </div>
            <div class="row">
                  <div class="col-md-12">
                        <label>Faça uma avaliação:</label>      
                        <textarea placeholder="Descreva a sua experiência..." [(ngModel)]="dataItem.descricao" name="descricao" rows="5"></textarea>
                  </div>
            </div>
            <div class="content-button">
                  <button type="button" class="btn-avaliacao" (click)="store()">
                        <i class="fa-solid fa-heart"></i>
                        <span>Fazer Avaliação</span>
                  </button>
            </div>
      </div>    
  </div>   
  <loader *ngIf="loader"></loader> 
  