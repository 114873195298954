import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'modulo-itens-destaque',
  templateUrl: './modulo-itens-destaque.component.html',
  styleUrls: ['./modulo-itens-destaque.component.scss']
})
export class ModuloItensDestaqueComponent implements OnInit {

  @Input("data") data:any = {};

  constructor(
    private dialog: MatDialog
  ) { }
  
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
