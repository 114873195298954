<div class="dm-cart-add" id="dm-cart-add">
  <div class="content" *ngIf="step != 8">
    <div class="row row-content">
      <div class="col-md-12">  
        <div id="dm-cart-add-content" class="dm-cart-add-content" [ngSwitch]="step">
          <div class="section-step animated fadeIn" *ngSwitchCase="'2'">
            <div class="section-step-title">
              <h3>Tipo do Serviço</h3>
            </div>
            <div class="section-step-content">
              <div class="content-message-loader">
                    <span class="message-loader-inline animated fadeIn" *ngIf="loaderTipoServico">
                      {{messageLoader}}
                    </span> 
                  </div>
                  <div class="content-response" *ngIf="dataTipos.status == 1">      
                    <div class="lista-tipo" *ngIf="dataTipos.data.length > 0">
                      <div class="lista-tipo-item" *ngFor="let item of dataTipos.data;let i=index;">
                        <div class="dm-check-box">
                          <span class="dm-check-box-input" [ngClass]="{'active':item.idTipoServico == dataItem.tipo_servico_id}" (click)="dataItem.tipo_servico_id=item.idTipoServico;dataItem.tipo_servico=item.tipoServico;"></span>
                          <span class="dm-check-box-value">{{item.tipoServico}} <span class="dm-check-box-info">- a partir de': {{item.lista | getTarifaMinimaLista | currency: "R$ "}}</span></span>  
                        </div>
                        <div class="lista-tipo-veiculos" *ngIf="item.idTipoServico == 2 && dataItem.tipo_servico_id == 2">
                          <hr>
                          <div class="lista-veiculos">
                            <div class="veiculo" *ngFor="let veiculo of item.lista;">
                              <div class="dm-check-box">
                                <span class="dm-check-box-input" [ngClass]="{'active':veiculo.idTipoTransporte == dataItem.tipo_transporte_id}" (click)="dataItem.tipo_transporte_id=veiculo.idTipoTransporte;dataItem.tipo_transporte=veiculo.tipoTransporte;dataItem.lotacao_maxima=veiculo.lotacaoMaxima;"></span>
                                <span class="dm-check-box-value">
                                  {{veiculo.tipoTransporte}} 
                                  <span class="dm-check-box-info"> 
                                    - Valor: {{veiculo.tarifaMinima | formatValorManager | currency:"R$ "}} - Lotação Máxima: {{veiculo.lotacaoMaxima}} lugares
                                  </span>  
                                </span>
                              </div>   
                            </div>  
                          </div>  
                        </div>  
                      </div>  
                    </div> 
                      <div *ngIf="dataTipos.data.length == 0">
                        Esse serviço está indisponível para compra.
                      </div>  
                    </div>  
                  </div>
                  <div class="section-step-footer" *ngIf="dataTipos.success == true">
                    <div class="navigation next">
                      <button type="button" (click)="next()" class="btn-one btn-next" [disabled]="loaderTipoServico">
                        <span>Próximo</span>
                        <i class="fa-solid fa-forward"></i>
                      </button>  
                    </div>  
                  </div>  
              </div>
              <div *ngIf="!servico.hotel_fixo && !dataItem.require_origem && !dataItem.require_destino">   
                <div class="section-step animated fadeIn" *ngSwitchCase="'3'">
                  <div class="section-step-title">
                    <h3>Onde vai ser hospedar?</h3>
                  </div>
                  <div class="section-step-content"> 
                    <combo-manager-hotel-v2 [id]="dataItem.servico_id" label="Selecionar Hospedagem:" (changeValue)="changeHotel($event)" [value]="dataItem.hotel_id" [text]="dataItem.nome_hotel"></combo-manager-hotel-v2>
                    <div class="form-custom content-input-endereco" *ngIf="dataItem.solicitar_endereco"> 
                      <label>Informe o endereço:</label>
                      <input type="text" [(ngModel)]="dataItem.endereco" name="endereco" />
                    </div>  
                    <div class="subscribe-message animated fadeIn" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
                  </div>  
                  <div class="section-step-footer">
                    <div class="navigation prev">
                      <button type="button" (click)="prev()" class="btn-three btn-prev" *ngIf="this.dataTipos.countTipo > 1">
                        <i class="fa-solid fa-backward"></i>
                        <span>Voltar</span>
                      </button>  
                    </div> 
                    <div class="navigation next">
                      <button  type="button" (click)="next()" class="btn-one btn-next">
                        <span>Próximo</span>
                        <i class="fa-solid fa-forward"></i>
                      </button>  
                    </div> 
                  </div>  
                </div>
              </div>
              <div *ngIf="dataItem.tipo_parametro_servico == 1 || dataItem.tipo_parametro_servico == 2 || dataItem.tipo_parametro_servico == 3">
                <div class="section-step animated fadeIn" *ngSwitchCase="'3'">
                  <div class="section-step-title" *ngIf="dataItem.require_origem">
                    <h3>{{'Local de Saída' | translate}}</h3>
                  </div>
                  <div class="content-origem" *ngIf="dataItem.require_origem">
                    <combo-manager-origem [id]="dataItem.servico_id" label="{{'Informe de onde você vai sair:' | translate}}" (changeValue)="changeLocalPickUp($event)" [value]="dataItem.id_local_pick_up" [text]="dataItem.local_pick_up"></combo-manager-origem>
                  </div>  
                  <div class="section-step-title" *ngIf="dataItem.require_destino">
                    <h3>{{'Local de Destino' | translate}}</h3>
                  </div>
                  <div class="content-destino" *ngIf="dataItem.require_destino">
                    <combo-manager-destino [id]="dataItem.servico_id" label="{{'Informe para onde você quer ir:' | translate}}" (changeValue)="changeLocalDropOff($event)" [value]="dataItem.id_local_drop_off" [text]="dataItem.local_drop_off"></combo-manager-destino>
                  </div>  
                  <div class="origem-hotel" *ngIf="(dataItem.tipo_local_origem == 'AEROPORTO' && dataItem.tipo_local_destino == 'AEROPORTO')">
                    <div class="section-step-title"> 
                      <h3>{{'Onde vai se hospedar?' | translate}}</h3>
                    </div>
                    <div class="section-step-content">
                      <combo-manager-hotel-v2 [id]="dataItem.servico_id" label="{{'Selecionar Hospedagem' | translate}}:" (changeValue)="changeHotel($event)" [value]="dataItem.hotel_id" [text]="dataItem.nome_hotel"></combo-manager-hotel-v2>
                      <div class="form-custom content-input-endereco" *ngIf="dataItem.solicitar_endereco">
                        <input type="text" [(ngModel)]="dataItem.endereco" name="endereco" placeholder="{{'Informe o Endereço' | trabslate}}" />
                      </div> 
                    </div>  
                  </div>
                  <div class="section-step-footer">
                    <div class="navigation prev"> 
                      <button type="button" title="Anterior" (click)="prev()" class="btn-three btn-prev">
                        <i class="fa-solid fa-backward"></i>
                        <span>Voltar</span>
                      </button>  
                    </div> 
                    <div class="navigation next">
                      <button type="button" title="Próximo" (click)="next()" class="btn-one btn-next">
                        <span>Próximo</span>
                        <i class="fa-solid fa-forward"></i>
                      </button>  
                    </div> 
                  </div>
                </div>
              </div>
              <div class="section-step animated fadeIn" *ngSwitchCase="'4'">
                <div class="section-step-title">
                  <h3>Informe a Quantidade de Passageiros</h3>
                </div>  
                <div class="section-step-content">
                  <dm-cart-passageiros-qtd [dataItem]="dataItem" [servico]="servico" [configuracaoLoja]="configuracaoLoja" [qtd_adt]="dataItem.qtd_adt" [min_adt]="servico.min_adt" [qtd_chd]="dataItem.qtd_chd" [qtd_snr]="dataItem.qtd_snr" [qtd_inf]="dataItem.qtd_inf" (changeValue)="changeQtds($event)"></dm-cart-passageiros-qtd>
                </div> 
                <div class="section-step-footer">
                  <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
                  <div class="navigation prev">
                    <button type="button" title="Anterior" (click)="prev()" class="btn-three btn-prev">
                      <i class="fa-solid fa-backward"></i>
                      <span>Voltar</span>
                    </button>  
                  </div> 
                  <div class="navigation next">  
                    <button type="button" title="Próximo" (click)="next()" class="btn-one btn-next">
                      <span>Próximo</span>
                      <i class="fa-solid fa-forward"></i>
                    </button>  
                  </div> 
                </div> 
              </div> 
              <div class="section-step animated fadeIn" *ngSwitchCase="'5'">
                <div class="section-step-title">
                  <h3>Escolha a Data</h3>
                </div>  
                <div class="section-step-content" *ngIf="step == 5">
                  <dm-cart-data-servico [snr]="dataItem.qtd_snr" [adt]="dataItem.qtd_adt" [frequenciaApartirDe]="frequenciaApartirDe" [value]="dataItem.data_utilizacao" [disponibilidadeArray]="disponibilidadeArray" (changeValue)="changeDataServico($event)" [idServico]="dataItem.servico_id" [idTipoServico]="dataItem.tipo_servico_id" [idMacroRegiao]="dataItem.macro_regiao_id" [idTipoTransporte]="dataItem.tipo_transporte_id"></dm-cart-data-servico> 
                  <div class="content-horario" *ngIf="dataItem.id_turno != 0">
                    <div class="section-step-title">
                      <h3>Este serviço tem mais de 1 horário</h3>
                    </div>
                    <div class="section-step-content">  
                      <combo-data-select [value]="dataItem.id_turno" [text]="dataItem.turno" label="Escolha o Horário:" [data]="comboTurno" (changeValue)="changeTurno($event)" name="id_turno"></combo-data-select>
                    </div>
                  </div>
                  <div class="content-valor" *ngIf="dataItem.valor_adt > 0">
                    <div class="item" *ngIf="servico.mostrar_adt">
                      <strong>Adulto: </strong>
                      <span>{{dataItem.valor_adt | currency: ' R$ '}}</span>
                    </div>
                    <div class="item" *ngIf="servico.mostrar_snr">
                      <strong>Melhor Idade: </strong>
                      <span>{{dataItem.valor_snr | currency: ' R$ '}}</span>
                    </div>
                    <div class="item" *ngIf="servico.mostrar_chd">
                      <strong>Criança: </strong>
                      <span>{{dataItem.valor_chd | currency: ' R$ '}}</span>
                    </div>
                    <div class="item" *ngIf="servico.mostrar_inf">
                      <strong>Infantil: </strong>
                      <span>{{dataItem.valor_inf | currency: ' R$ '}}</span>
                    </div>
                  </div>
                </div> 
                <div class="section-step-footer">
                  <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
                  <div class="navigation prev">
                    <button type="button" title="Anterior" (click)="prev()" class="btn-three btn-prev">
                      <i class="fa-solid fa-backward"></i>
                      <span>Voltar</span>
                    </button>  
                  </div> 
                  <div class="navigation next">
                    <button type="button" title="Próximo" (click)="next()" class="btn-one btn-next">
                      <span>Próximo</span>
                      <i class="fa-solid fa-forward"></i>
                    </button>  
                  </div> 
                </div>  
              </div>  
              <div class="section-step animated fadeIn" *ngSwitchCase="'6'" [hidden]="!pedirDadosVoo">
                <div>
                  <div class="section-step-title">  
                    <h3>Informe os Dados do Voo</h3>
                  </div> 
                  <div class="section-step-content">
                    <div class="individual" *ngIf="!dataItem.pacote_in_out">  
                      <dm-cart-dados-voo (changeHora)="changeHoraVoo($event)" (changeVoo)="changeVoo($event)" [hora]="dataItem.hora_voo" [voo]="dataItem.voo" (changeCiaAerea)="changeCia($event)" (changeAeroporto)="changeAeroporto($event)"></dm-cart-dados-voo>
                    </div>  
                    <div class="pacote" *ngIf="dataItem.pacote_in_out">  
                      <span>Dados de Chegada:</span>        
                      <dm-cart-dados-voo (changeHora)="changeHoraChegada($event)"  [hora]="dataItem.hora_chegada" [aeroporto]="dataItem.aeroporto_chegada" [ciaText]="dataItem.cia_chegada_text" [ciaValue]="dataItem.cia_chegada_codigo" (changeVoo)="changeVooIn($event)" [voo]="dataItem.voo_chegada" (changeCiaAerea)="changeCiaIn($event)" (changeAeroporto)="changeAeroportoIn($event)"></dm-cart-dados-voo>
                      <hr>
                      <span>Dados de Retorno:</span> 
                      <dm-cart-dados-voo [dataUtilizacao]="dataItem.data_utilizacao" [data]="dataItem.data_partida" [aeroporto]="dataItem.aeroporto_partida" [ciaText]="dataItem.cia_partida_text" [ciaValue]="dataItem.cia_partida_codigo" (changeData)="changeDataRetorno($event)"  (changeHora)="changeHoraPartida($event)" [hora]="dataItem.hora_partida" [voo]="dataItem.voo_partida" (changeVoo)="changeVooOut($event)" (changeCiaAerea)="changeCiaOut($event)" (changeAeroporto)="changeAeroportoOut($event)"></dm-cart-dados-voo>
                    </div>    
                  </div>
                </div>
                <div class="section-step-footer">
                  <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
                  <div class="navigation prev">
                    <button type="button" title="Anterior" (click)="prev()" class="btn-three btn-prev">
                      <i class="fa-solid fa-backward"></i>
                      <span>Voltar</span>
                    </button>  
                  </div> 
                  <div class="navigation next">
                    <button type="button" title="Próximo" (click)="next()" class="btn-one btn-next">
                      <span>Próximo</span>
                      <i class="fa-solid fa-forward"></i>
                    </button>  
                  </div> 
                </div>   
            </div>
            <div class="section-step animated fadeIn" *ngSwitchCase="'7'">
              <div class="section-step-title">
                <h3>Verifique os dados e adicione ao carrinho</h3>
              </div>  
              <div class="section-step-content">
                <div class="resumo">
                  <div class="item">
                    <strong>Tipo de Serviço:</strong> 
                    <span>{{dataItem.tipo_servico}}</span>
                  </div> 
                  <div class="item">
                    <strong>Hotel:</strong>
                    <span>{{dataItem.nome_hotel}}</span>
                  </div>
                  <div class="item">
                    <strong>Data Utilização:</strong> 
                    <span>{{dataItem.data_utilizacao}}</span>
                  </div> 
                  <div class="item" *ngIf="dataItem.local_pick_up != ''">
                    <strong>Local de Saída:</strong>
                    <span>{{dataItem.local_pick_up}}</span>
                  </div>
                  <div class="item" *ngIf="dataItem.local_drop_off != ''">
                    <strong>Local de Destino:</strong>
                    <span>{{dataItem.local_drop_off}}</span>
                  </div>
                  <div class="item" *ngIf="dataItem.qtd_adt > 0">
                    <strong>Adultos: {{dataItem.qtd_adt}}</strong>
                    <span>{{(dataItem.valor_adt*dataItem.qtd_adt) | currency: " R$ "}}</span>
                  </div> 
                  <div class="item" *ngIf="dataItem.qtd_snr > 0">
                    <strong>Melhor Idade: {{dataItem.qtd_snr}}</strong>
                    <span>{{(dataItem.valor_snr*dataItem.qtd_snr) | currency: " R$ "}}</span>
                  </div> 
                  <div class="item" *ngIf="dataItem.qtd_chd > 0">
                    <strong>Crianças: {{dataItem.qtd_chd}}</strong>
                    <span>{{(dataItem.valor_chd*dataItem.qtd_chd) | currency: " R$ "}}</span>
                  </div> 
                  <div class="item" *ngIf="dataItem.qtd_inf > 0">
                    <strong>Infantil: {{dataItem.qtd_inf}}</strong>
                    <span>{{(dataItem.valor_inf*dataItem.qtd_inf) | currency: " R$ "}}</span>
                  </div>
                  <div class="item">
                    <strong>Total:</strong>    
                    <span *ngIf="dataItem.tipo_servico_id == 1">{{(dataItem.valor_snr*dataItem.qtd_snr)+(dataItem.valor_adt*dataItem.qtd_adt)+(dataItem.valor_chd*dataItem.qtd_chd)+(dataItem.valor_inf*dataItem.qtd_inf) | currency: "R$ "}}</span>
                    <span *ngIf="dataItem.tipo_servico_id == 2">{{dataItem.valor_servico | currency: "R$ "}}</span>
                  </div> 
                </div> 
              </div> 
              <div class="section-step-footer">
                <div class="subscribe-message" *ngIf="subscribeMessage != null" [innerHtml]="subscribeMessage"></div>
                <div class="navigation prev">
                  <button type="button" title="Anterior" (click)="prev()" class="btn-three btn-prev" *ngIf="!servico.vale_presente">
                    <i class="fa-solid fa-backward"></i>
                    <span>Voltar</span>
                  </button>  
                </div> 
                <div class="navigation next">  
                  <button type="button" title="Próximo" (click)="addCart()" class="btn-reserve" [disabled]="loader || loaderCarrinho">
                    <span>Adicionar ao Carrinho</span>   
                    <i class="fa-solid fa-cart-shopping"></i>
                  </button>   
                </div> 
              </div> 
          </div>
        </div> 
      </div> 
    </div>
  </div>
  <div class="content" *ngIf="step == 8">
    <div class='add-confirm'>
      <div class="header">
        <div class="icon">
          <i class="fa-solid fa-circle-check"></i>
        </div> 
        <span class="nome">{{dataItem.nome}}</span>
        <span class="title">foi adicionado ao carrinho!</span>
      </div> 
      <div class="content">
        <div class="item" *ngIf="dataItem.qtd_adt > 0">
          <strong>Adultos: {{dataItem.qtd_adt}}</strong>
          <span>{{(dataItem.valor_adt*dataItem.qtd_adt) | currency: " R$ "}}</span>
        </div> 
        <div class="item" *ngIf="dataItem.qtd_snr > 0">
          <strong>Melhor Idade: {{dataItem.qtd_snr}}</strong>
          <span>{{(dataItem.valor_snr*dataItem.qtd_snr) | currency: " R$ "}}</span>
        </div> 
        <div class="item" *ngIf="dataItem.qtd_chd > 0">
          <strong>Criança: {{dataItem.qtd_chd}}</strong>
          <span>{{(dataItem.valor_chd*dataItem.qtd_chd) | currency: " R$ "}}</span>
        </div> 
        <div class="item" *ngIf="dataItem.qtd_inf > 0">
          <strong>Infantil: {{dataItem.qtd_inf}}</strong>
          <span>{{(dataItem.valor_inf*dataItem.qtd_inf) | currency: " R$ "}}</span>
        </div>  
      </div>
      <div class="footer">
        <a href="javascript::void();" (click)="_toRoute('/servicos')" class="btn-one btn-icon" title="Continuar comprando">
          <i class="material-icons">add_shopping_cart</i>
          <span>Continuar comprando</span>
        </a>  
        <a href="javascript::void();" (click)="_toRoute('/carrinho')" class="btn-two btn-icon btn-paid" title="Finalizar compra">
          <i class="material-icons">paid</i>
          <span>Finalizar minhas compras</span>
        </a>  
      </div>  
    </div>  
  </div> 
</div>   
<loader [message]="messageLoader" *ngIf="loader"></loader>  
