import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoVideoModal } from '../produto-video-modal/produto-video-modal.component';
import { ProdutoGaleriaModal } from '../produto-galeria-modal/produto-galeria-modal.component';

@Component({
  selector: 'produto-galeria',
  templateUrl: './produto-galeria.component.html',
  styleUrls: ['./produto-galeria.component.scss']
})
export class ProdutoGaleriaComponent implements OnInit {

  @Input("fotos") fotos:any     = [];
  @Input("servico") servico:any = {}; 
  public _openGallery = new EventEmitter();

  public _fotos = [{
    imagem: "/images/produto/sem-foto/brocker.jpg",
    titulo: "Brocker Turismo",
    alt: "Brocker Turismo - Serra Gaúcha"
  },{
    imagem: "/images/produto/sem-foto/brocker.jpg", 
    titulo: "Brocker Turismo",
    alt: "Brocker Turismo - Serra Gaúcha"
  },{
    imagem: "/images/produto/sem-foto/brocker.jpg",
    titulo: "Brocker Turismo",
    alt: "Brocker Turismo - Serra Gaúcha"
  }];
  public defaultImage = "/images/produto/sem-foto/brocker.jpg"; 

  constructor(
    private dialog: MatDialog
  ){} 

  /**
   * 
   * Set fotos
   * 
   */
  setFotos(){

    if(typeof(this.fotos[0]) != "undefined"){
      this._fotos[0] = this.fotos[0];
    }
    if(typeof(this.fotos[1]) != "undefined"){
      this._fotos[1] = this.fotos[1];
    }
    if(typeof(this.fotos[2]) != "undefined"){
      this._fotos[2] = this.fotos[2];
    }

  }
  /**
   * 
   * Open galeria
   * 
   */
  _openGaleria(){ 

    this._openGallery.emit(); 

  }
  /**
   * 
   * Open Video
   * 
   */
  _openVideo(){

    this.dialog.open(
      ProdutoVideoModal,{
        width: "800px",
        height: "auto",
        disableClose: true, 
        data: {
          video: this.servico.link_video
        }
      }
    )

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.setFotos();
  }

}
