import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var $:any;

@Component({
  selector: 'modulo-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.scss']
})
export class AvaliacaoComponent implements OnInit {

  @Input("data") data:any;
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  @ViewChild("slider") slider:any; 
  public produto:any = {};
  
  constructor(
    private sanitize: DomSanitizer
  ){}   
  
  /**
   * 
   * Format url video
   * 
   */
  formatUrlVideo(url){

    if(url.indexOf('?v=') > -1){

      let base = "https://www.youtube.com/embed/";

      let s = url.split("?v=");  

      base = base+s[1];

      return base;

    }

  }
  /**
   * 
   * Set url
   * 
   */
  getUrl(url:any){

    return this.sanitize.bypassSecurityTrustResourceUrl(this.formatUrlVideo(url));    
     
  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({  
      infinite: true, 
      slidesToShow: 3,     
      dots: false,
      arrows: false,
      centerMode: false,
      centerPadding: '5px',
      lazyLoad: "progressive",
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3, 
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh');  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /****
   * 
   * Init
   * 
   ***/
  ngOnInit():void{

    if(typeof(this.data.produto) != null){

      this.produto = this.data.produto.produto;  

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
