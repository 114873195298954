import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:any;
  public classCustom = "";
  @Input("classCustomTwo") classCustomTwo:any = "";

  constructor(){}
  
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
   setFixed(){

      let header = this.header.nativeElement;

      $(window).scroll(() => {
        
        var fromTop     = $(window).scrollTop();
        
        if(fromTop > 100){
          header.classList.add("animated","fadeIn","fixed");
        }else{
          header.classList.remove("fixed","animated","fadeIn");
        }

      });

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    if(window.innerWidth >= 767){
      this.classCustom = "";
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    this.setFixed();  
    this.onResize();
  }


}
