import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'produto-avaliacao-star',
  templateUrl: './produto-avaliacao-star.component.html',
  styleUrls: ['./produto-avaliacao-star.component.scss']
})
export class ProdutoAvaliacaoStarComponent implements OnInit {

  @Input("nota") nota:any         = {};
  @Input("template") template:any = 1;  
  @Input("notaServico") notaServico:any  = 0;

  constructor(){}

  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}

}
