import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboManagerOrigemComponent } from 'src/app/components/combos/combo-manager-origem/combo-manager-origem.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ComboManagerOrigemComponent
  ],
  exports: [
    ComboManagerOrigemComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class ComboOrigemModule { }
