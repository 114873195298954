import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'produto-show-modal',
  templateUrl: './produto-show-modal.component.html',
  styleUrls: ['./produto-show-modal.component.scss']
})
export class ProdutoShowModalComponent implements OnInit {

  public loader:any = null; 
  public produto:any
  public title:any = "";
  public data:any = null;
  public response:any = {
    data: null,
    status: null
  }

  constructor(
    public dialog: MatDialogRef<ProdutoShowModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    public api: ApiService
  ){

    this.produto = this._data.produto;
    this.getData();

  }
  /**
   * 
   * Get Data
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().produto(this.produto.apelido).subscribe((response:any) => {

        this.loader = false;

        if(response.status == 1){

          this.data = response.data;
          this.title = this.data.produto.nome;
          this.response = {
            data: null,
            status: 1
          }

        }else{

          let error   = this.api.formatError(response);
          this.response = {
            data: error.message,
            status: 500
          }

        }

      },(response:any) => {

        this.loader = false;
        let error   = this.api.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e:any){

      this.loader = false;
      this.response = {
        data: e.message,
        status: 500
      }

    }

  }
  /**
   * 
   * Init
   * 
   **/
  ngOnInit():void{}

}
