import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnAddCartComponent } from 'src/app/components/carrinho/btn/btn-add-cart/btn-add-cart.component';
import { DmCartAdd } from 'src/app/components/carrinho/dm-cart-add/dm-cart-add.component';
import { DmCardEmailModal } from 'src/app/components/carrinho/modal/dm-card-email/dm-card-email.component';
import { FormsModule } from '@angular/forms';
import { DmCardAddModal } from 'src/app/components/carrinho/modal/dm-card-add-modal/dm-card-add-modal.component';
import { CartDataServicoModule } from '../cart-data-servico/cart-data-servico.module';
import { ComboAeroportoModule } from '../../combo/combo-aeroporto/combo-aeroporto.module';
import { ComboCiaAereaModule } from '../../combo/combo-cia-aerea/combo-cia-aerea.module';
import { ComboHotelModule } from '../../combo/combo-hotel/combo-hotel.module';
import { CartPassageirosQtdModule } from '../cart-passageiros-qtd/cart-passageiros-qtd.module';
import { CartResumoStepsModule } from '../cart-resumo-steps/cart-resumo-steps.module';
import { LoaderModule } from '../../loader/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CartDadosVooModule } from '../card-dados-voo/card-dados-voo.module';
import { PipeModule } from '../../pipe/pipe.module';
import { ComboOrigemModule } from '../../combo/combo-origem/combo-origem.module';
import { ComboDestinoModule } from '../../combo/combo-destino/combo-destino.module';
import { ComboDataSelectModule } from '../../combo/combo-data-select/combo-data-select.module';
import { DmCardAddPrimeComponent } from 'src/app/components/carrinho/dm-card-add-prime/dm-card-add-prime.component';
import { BtnReservaComponent } from 'src/app/components/carrinho/btn/btn-reserva/btn-reserva.component';

@NgModule({
    declarations: [
        BtnReservaComponent,
        BtnAddCartComponent,
        DmCartAdd,
        DmCardEmailModal,
        DmCardAddModal,
        DmCardAddPrimeComponent
    ],
    exports: [
        BtnReservaComponent,
        BtnAddCartComponent,
        DmCartAdd,
        DmCardEmailModal,
        DmCardEmailModal,
        DmCardAddPrimeComponent
    ],
    imports: [
        CartDadosVooModule,
        CartPassageirosQtdModule,
        CartResumoStepsModule,
        ComboAeroportoModule,
        ComboCiaAereaModule,
        ComboHotelModule,
        ComboOrigemModule,
        ComboDestinoModule,
        CartDataServicoModule,
        FormsModule,
        CommonModule,
        LoaderModule,
        LazyLoadImageModule,
        PipeModule,
        ComboDataSelectModule
    ]
})
export class CartAddModule{} 
