import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorService } from 'src/app/services/validator.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'dm-card-email',
  templateUrl: './dm-card-email.component.html',
  styleUrls: ['./dm-card-email.component.scss']
})
export class DmCardEmailModal implements OnInit {

  public email         = null;
  public emailSugestao = null;

  constructor(
    public dialog: MatDialogRef<DmCardEmailModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private validator: ValidatorService,
    private app: AppService
  ){}

  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close();

  }
  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.email,"email","- Informe o e-mail.");
      this.validator.isEmpty(this.email,"email","- O e-mail informado é inválido.");

      if(this.validator.passes()){

        this.data.self.dataItem.email = this.email;
        this._close();
        this.data.self.addCart(); 

      }else{

        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger");  

      }


    }catch(e){

    }

  }
  _usar(){

    this.email = this.emailSugestao;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.emailSugestao = this.data.email;
  }

}
