import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomePage } from 'src/app/pages/home-page/home-page.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { PartialModule } from '../partial/partial.module';
import { ModuloModule } from '../modulo/modulo.module';
import { BtnUsuarioLoginModule } from '../btn/btn-usuario-login/btn-usuario-login.module';
import { LoaderModule } from '../loader/loader/loader.module';
import { HeaderComponent } from 'src/app/components/partial/header/header.component';
import { MenuComponent } from 'src/app/components/partial/menu/menu.component';
import { LgListComponent } from 'src/app/components/partial/lg-list/lg-list.component';

@NgModule({
  declarations: [
    HomePage,
    HeaderComponent,
    MenuComponent,
    LgListComponent
  ],
  exports: [
    HomePage,
    HeaderComponent,
    MenuComponent,
    LgListComponent
  ],
  imports: [
    BtnUsuarioLoginModule,  
    ModuloModule,
    LazyLoadImageModule,
    PartialModule,
    CommonModule,
    HomeRoutingModule,
    LoaderModule
  ],  
  providers: []
})
export class HomeModule { }
