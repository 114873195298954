<div class="modulo banner-slider banner-slider-slick">
  <div class="navigate">
    <div class="navigate-item prev" (click)="prevSlide()">
        <div class="content">
          <img src="images/icons/prev-01.svg" alt="Prev" width="60px" />
        </div>
    </div> 
    <div class="navigate-item next" (click)="nextSlide()">
        <div class="content">
          <img src="/images/icons/next-01.svg" alt="Next" width="60px" />
        </div>
    </div>    
  </div> 
  <div class="titles">
    <span class="subtitle">BEM VINDO</span>
    <span class="title">{{data.titulo}}</span>
    <span class="description wow slideInUp">{{data.subtitulo}}</span>
  </div>
  <div #banner class="banner-slider-slick-content">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img width="100%" *ngIf="width > 700"  [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
        <img width="100%" *ngIf="width <= 700" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img width="100%" *ngIf="width > 700"  [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
            <img width="100%" *ngIf="width <= 700" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img width="100%" *ngIf="width > 700"  [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
            <img width="100%" *ngIf="width <= 700" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img width="100%" *ngIf="width > 700" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
            <img width="100%" *ngIf="width <= 700" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>   
      </div>
    </div>
  </div>
</div>


