import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router, Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'dm-card-add-modal',
  templateUrl: './dm-card-add-modal.component.html',
  styleUrls: ['./dm-card-add-modal.component.scss']
})
export class DmCardAddModal implements OnInit {

  public loader           = false;
  public macroRegiao      = null;
  public configuracaoLoja = null;
  public categoria        = null;
  public servico          = null; 
  public horarios         = null;
  public idCart           = null;
  public valor            = null;
  public emailSugestao    = null;
  public email            = null;
  public cartService      = null;
  public type             = 1;

  constructor(
    public dialog: MatDialogRef<DmCardAddModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ){

    this.macroRegiao      = this.data.macroRegiao;
    this.configuracaoLoja = this.data.configuracaoLoja;
    this.categoria        = this.data.categoria;
    this.servico          = this.data.servico;
    this.horarios         = this.data.horarios;
    this.idCart           = this.data.idCart;
    this.valor            = this.data.valor;
    this.emailSugestao    = this.data.emailSugestao;
    this.email            = this.data.email;
    this.cartService      = this.data.cartService; 
    this.type             = this.data.type; 
    
  }
  /***
   * 
   * Close
   * 
   */
  _close(){
    this.dialog.close(); 
  }
  /***
   * 
   * On events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        
        

      }
      if(event instanceof NavigationEnd){

        this._close();
      
      }

    });

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onEvents();
  }

}
