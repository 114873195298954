<div class="product-item {{classCustom}}">
  <div class="capa" (click)="_click()" *ngIf="lazy">
    <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
      <img width="100%"  defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
    </a>  
    <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
      <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
    </a>   
  </div>
  <div class="capa" (click)="_click()" *ngIf="!lazy">
    <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
      <img width="100%" [src]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
    </a>  
    <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
      <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
    </a>   
  </div>
  <div class="content">
      <h4>{{produto.nome}}</h4>
      <article *ngIf="produto.descricao_listagem != null && produto.descricao_listagem != ''" [innerHtml]="produto.descricao_listagem | safeHtml"></article>
      <div class="info">  
          <div class="item" *ngIf="produto.duracao != null">
              <div class="icon">
                  <i class="fa-solid fa-clock"></i>
              </div>
              <span>{{produto.duracao | formatDuracao}}</span>
          </div>
          <div class="item">
              <div class="icon">
                  <i class="fa-solid fa-calendar-day"></i>
              </div>
              <span>{{produto.dias | formatDias}}</span>
          </div>     
      </div>   
      <div class="content-valor">
          <div class="icon">
              <i class="fa-solid fa-money-bill"></i>
          </div>
          <div class="tariff">
            <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
          </div>   
      </div>    
      <div class="footer">
          <a href="#" alt="Ver produto" class="link-product" (click)="open()">
            <span>Saiba Mais</span>
          </a>   
      </div> 
      <div class="installments" *ngIf="produto.mostrar_parcelas">
        <span>Em até <strong>{{produto.parcelas}}x</strong> sem juros</span> 
      </div>
  </div>    
</div>   
