import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produto-destaques',
  templateUrl: './produto-destaques.component.html',
  styleUrls: ['./produto-destaques.component.scss']
})
export class ProdutoDestaquesComponent implements OnInit {

  @Input("data") data;
  @Input("classCustom") classCustom = "";
  @Input("template") template       = null;  
  public produtos                   = []; 

  constructor() { }

  /**
   * 
   * Seta os produtos
   * 
   */
  setProdutos(){

    if(typeof(this.data.produtos) != "undefined"){
      this.produtos = this.data.produtos;
    }

  }
  /**
   * 
   * Inicializas as Funções
   * 
   */
  ngOnInit(){
    this.setProdutos();
  }

}
