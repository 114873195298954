<div class="module-spotlight-items">
      <div class="container">
          <div class="items">
              <div class="box wow slideInUp" *ngFor="let d of data.data" [ngClass]="{'box-reserva': d.botao_compra_ativo}" [style.background]="d.cor_bg">
                  <div class="icon" *ngIf="d.icone != null && d.icone != ''">
                      <img [src]="d.icone" [alt]="d.titulo" height="100px" />
                  </div>
                  <h3 [style.color]="d.cor_titulo">{{d.titulo}}</h3>  
                  <article [style.color]="d.cor_texto" [innerHtml]="d.texto | safeHtml"></article>
                  <btn-reserva *ngIf="d.botao_compra_ativo" [produto]="d" [id]="d.produto_id" class="wow slideInUp"></btn-reserva>
              </div>  
          </div>
      </div>
 </div>
  