import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { BannerSliderSlickComponent } from 'src/app/modulos/banner-slider-slick/banner-slider-slick.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { RouterModule } from '@angular/router';
import { MenuFooterComponent } from 'src/app/components/partial/menu-footer/menu-footer.component';
import { RedeSocialComponent } from 'src/app/modulos/rede-social/rede-social.component';
import { ModulosDestaqueComponent } from 'src/app/components/modulo/modulos-destaque/modulos-destaque.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvaliacaoComponent } from 'src/app/modulos/avaliacao/avaliacao.component';
import { ModuloItensDestaqueComponent } from 'src/app/modulos/modulo-itens-destaque/modulo-itens-destaque.component';
import { ModuloDuvidaFrequenteComponent } from 'src/app/modulos/modulo-duvida-frequente/modulo-duvida-frequente.component';
import { ModuloParalaxComponent } from 'src/app/modulos/modulo-paralax/modulo-paralax.component';
import { ModuloProdutoComponent } from 'src/app/modulos/modulo-produto/modulo-produto.component';
import { ProdutoVideoComponent } from 'src/app/components/produto/produto-video/produto-video.component';
import { CartAddModule } from '../carrinho/cart-add/cart-add.module';
import { ProdutoDestaquesComponent } from 'src/app/modulos/produto-destaques/produto-destaques.component';
import { ProdutoGaleriaModule } from '../produto/produto-galeria/produto-galeria.module';

@NgModule({
  declarations: [
    Modulo,
    BannerSliderSlickComponent,
    EnderecoComponent,
    MenuFooterComponent,
    RedeSocialComponent,
    ModulosDestaqueComponent,
    AvaliacaoComponent,
    ModuloItensDestaqueComponent,
    ModuloDuvidaFrequenteComponent,
    ModuloParalaxComponent,
    ModuloProdutoComponent,
    ProdutoVideoComponent,
    ProdutoDestaquesComponent
  ],
  exports:[
    Modulo,
    BannerSliderSlickComponent,
    EnderecoComponent,
    MenuFooterComponent,
    RedeSocialComponent,
    ModulosDestaqueComponent,
    AvaliacaoComponent,
    ModuloItensDestaqueComponent,
    ModuloDuvidaFrequenteComponent,
    ModuloParalaxComponent,
    ModuloProdutoComponent,
    ProdutoVideoComponent,
    ProdutoDestaquesComponent
  ],
  imports: [
    RouterModule,
    ModuloWhatsappModule,  
    ProdutoPartialModule,
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    CommonModule,
    PipeModule,  
    ReactiveFormsModule,
    FormsModule,
    CartAddModule,
    ProdutoGaleriaModule
  ]
})
export class ModuloModule{}
