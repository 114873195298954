import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DmCardAddModal } from '../../modal/dm-card-add-modal/dm-card-add-modal.component';
import { DataService } from 'src/app/services/data.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'btn-reserva',
  templateUrl: './btn-reserva.component.html',
  styleUrls: ['./btn-reserva.component.scss']
})
export class BtnReservaComponent implements OnInit {

  @Input("id") id:any = null;
  @Input("produto") produto:any = null;
  public configuracaoLoja = null;
  public macroRegiao = null;
  public categoria = null;
  public card

  constructor(
    private dialog: MatDialog,
    private data: DataService,
    private cart: CartService
  ) { 

    this.configuracaoLoja = this.data.getConfiguracao();
    this.macroRegiao      = this.data.getMacroRegiao();

    
  }
  /**
   * 
   * Open
   * 
   */
  open(){

    this.dialog.open(DmCardAddModal,{
      width: "500px",
      data: {
        macroRegiao: this.macroRegiao,
        configuracaoLoja: this.configuracaoLoja,
        categoria: this.categoria,
        servico: this.produto,
        idCart: null,
        //valor: this.valor,
        emailSugestao: null,
        email: null,
        cartService: this.cart
      }
    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
