<div class="cart-total content-action" (click)="_onClick()" [ngClass]="{'template-lp': lp != null}">
    <div class="item item-cart">
        <a routerLink="/carrinho" title="Carrinho de Compras">
            <i class="material-icons">shopping_bag</i>
            <span>Sacola</span>
        </a>    
        <span [style.background]="color" class="qtd">{{qtd}}</span>    
    </div>    
</div>   

