import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'produto-video',
  templateUrl: './produto-video.component.html',
  styleUrls: ['./produto-video.component.scss']
})
export class ProdutoVideoComponent implements OnInit {

  @Input("url") url = null;
  public _url = null;

  constructor(
    private sanitize: DomSanitizer
  ){ }

   /**
   * 
   * Format url video
   * 
   */
    formatUrlVideo(url){

      if(url.indexOf('?v=') > -1){
  
        let base = "https://www.youtube.com/embed/";
  
        let s = url.split("?v=");  
  
        base = base+s[1];
  
        return base;
  
      }
  
    }
  /**
   * 
   * Set url
   * 
   */
  setUrl(){

    this._url = this.sanitize.bypassSecurityTrustResourceUrl(this.formatUrlVideo(this.url));    
     
  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setUrl();
  }

}
