import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoGaleriaComponent } from 'src/app/components/produto/produto-galeria/produto-galeria.component';
import { ProdutoGaleriaTwoComponent } from 'src/app/components/produto/produto-galeria-two/produto-galeria-two.component';
import { ProdutoGaleriaModal } from 'src/app/components/produto/produto-galeria-modal/produto-galeria-modal.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    ProdutoGaleriaComponent,
    ProdutoGaleriaTwoComponent,
    ProdutoGaleriaModal  
  ],
  exports: [
    ProdutoGaleriaComponent,
    ProdutoGaleriaTwoComponent,
    ProdutoGaleriaModal,
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ProdutoGaleriaModule{}
