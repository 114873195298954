<div class="dm-cart-resumo-steps" [hidden]="step == 1">
  <ul>
    <li *ngIf="step >= 2" (click)="setStep(2)">
      <div class="item">
        <div class="icon">  
          <img width="18px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/car.svg" alt="Tipo de Serviço" />
        </div> 
        <div class="text"> 
          <span>{{dataItem.tipo_servico}}</span>
          <span *ngIf="dataItem.tipo_servico_id == 2" class="info">
            - {{dataItem.tipo_transporte}}
          </span>
        </div>  
      </div>  
    </li>   
    <li *ngIf="step >= 3 && !dataItem.hotel_fixo" (click)="setStep(3)">
      <div class="item">
        <div class="icon">
          <img width="18px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/hotel.svg" alt="Tipo de Serviço" />
        </div>
        <div class="text"> 
          <span>{{dataItem.nome_hotel}}</span>
        </div>  
      </div>
    </li>
    <li *ngIf="step >= 4" class="step-qtd" (click)="setStep(4)">
      <div class="item item-passageiros">
       <div class="icon"> 
         <img width="18px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/people.svg" alt="Tipo de Serviço" />
       </div>
       <div class="text"> 
         <div class="item-qtd" *ngIf="servico.mostrar_adt">
           <strong>Adultos:</strong> <span>{{dataItem.qtd_adt}}</span>
         </div>
         <div class="item-qtd" *ngIf="servico.mostrar_snr">  
           <strong>Melhor Idade:</strong> <span>{{dataItem.qtd_snr}}</span>
         </div>
         <div class="item-qtd" *ngIf="servico.mostrar_chd">
           <strong>Criança:</strong> <span>{{dataItem.qtd_chd}}</span>
         </div>
         <div class="item-qtd" *ngIf="servico.mostrar_inf">
           <strong>Infantil:</strong> <span>{{dataItem.qtd_inf}}</span>
         </div>
       </div>  
     </div>  
   </li>
    <li *ngIf="step >= 5" (click)="setStep(5)">
      <div class="item">  
        <div class="icon">
          <img width="18px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/calendar.svg" alt="Tipo de Serviço" />
        </div>
        <div class="text"> 
          <span>{{dataItem.data_utilizacao}}</span>
        </div>  
      </div> 
    </li>
    <li *ngIf="step >= 5" class="step-valores">
      <div class="item totais">
        <div class="icon">
          <img width="18px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/money.svg" alt="Tipo de Serviço" />
        </div>
        <div class="text" *ngIf="dataItem.tipo_servico_id == 1">  
          <span>TOTAL: </span><strong>{{((dataItem.valor_snr*dataItem.qtd_snr)+(dataItem.valor_adt*dataItem.qtd_adt)+(dataItem.valor_chd*dataItem.qtd_chd)+(dataItem.valor_inf*dataItem.qtd_inf)) | currency: "R$ "}}</strong>
        </div>  
        <div class="text" *ngIf="dataItem.tipo_servico_id == 2">  
          <span>Valor Serviço: </span><strong>{{dataItem.valor_servico | currency: "R$ "}}</strong><br>
          <span>Lotação Máxima: </span><strong>{{dataItem.lotacao_maxima}} lugares</strong>
        </div>  
      </div>  
    </li> 
  </ul> 
</div>  