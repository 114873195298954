<div class="dm-cartd-passageiros-qtd">
  <form class="form-custom">
    <div class="row">
      <div class="col-md-6" *ngIf="servico.mostrar_adt">
        <div class="item">
          <div class="content">
            <label>Adultos</label>
            <span class="info">
              {{configuracaoLoja.passageiro_label_adt}}
            </span>
            <div class="content-input">  
              <input type="text" [(ngModel)]="qtd_adt" name="qtd_adt" integer (change)="_changeAdt()" />
              <div class="content-buttons">
                <button type="button" class="btn-one menos" (click)="menos('adt')">
                  <span>-</span>
                </button> 
                <button type="button" class="btn-one mais" (click)="mais('adt')">
                  <span>+</span>
                </button>  
              </div>
            </div> 
          </div>  
        </div>  
      </div> 
      <div class="col-md-6" *ngIf="servico.mostrar_snr"> 
        <div class="item">
          <div class="content">
            <label>Melhor Idade</label>
            <span class="info">
              {{configuracaoLoja.passageiro_label_snr}}
            </span>
            <div class="content-input">
              <input type="text" [(ngModel)]="qtd_snr" name="qtd_snr" integer (change)="_changeValue()" />
              <div class="content-buttons">
                <button type="button" class="btn-one menos" (click)="menos('snr')">
                  <span>-</span>
                </button> 
                <button type="button" class="btn-one mais" (click)="mais('snr')">
                  <span>+</span>
                </button>  
              </div>
            </div>   
          </div> 
        </div>
      </div>
      <div class="col-md-6" *ngIf="servico.mostrar_chd">
        <div class="item">
          <div class="content">
            <label>Criança</label>
            <span class="info">
              {{configuracaoLoja.passageiro_label_chd}}
            </span>
            <div class="content-input">
              <input type="text" [(ngModel)]="qtd_chd" name="qtd_chd" integer (change)="_changeValue()" />
              <div class="content-buttons">
                <button type="button" class="btn-one menos" (click)="menos('chd')">
                  <span>-</span>
                </button> 
                <button type="button" class="btn-one mais" (click)="mais('chd')">
                  <span>+</span>
                </button>  
              </div>
            </div>  
          </div> 
        </div>
      </div>  
      <div class="col-md-6" *ngIf="servico.mostrar_inf">
        <div class="item">
          <div class="content">
            <label>Infantil</label>
            <span class="info">
              {{configuracaoLoja.passageiro_label_chd_free}}
            </span>
            <div class="content-input">
              <input type="text" [(ngModel)]="qtd_inf" name="qtd_inf" integer (change)="_changeValue()"/>
              <div class="content-buttons">
                <button type="button" class="btn-one menos" (click)="menos('inf')">
                  <span>-</span>
                </button> 
                <button type="button" class="btn-one mais" (click)="mais('inf')">
                  <span>+</span>
                </button>  
              </div>
            </div>   
          </div> 
        </div>
      </div>   
    </div> 
  </form>   
</div>

