import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartDadosVooComponent } from 'src/app/components/carrinho/dm-cart-dados-voo/dm-cart-dados-voo.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ComboAeroportoModule } from '../../combo/combo-aeroporto/combo-aeroporto.module';
import { ComboCiaAereaModule } from '../../combo/combo-cia-aerea/combo-cia-aerea.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    DmCartDadosVooComponent 
  ],
  exports: [
    DmCartDadosVooComponent
  ],
  imports: [
    DirectivesModule,
    ComboAeroportoModule,
    ComboCiaAereaModule,
    CommonModule,
    LoaderModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule 
  ]
})
export class CartDadosVooModule { }
