import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'combo-aeroporto-v2',
  templateUrl: './combo-aeroporto-v2.component.html',
  styleUrls: ['./combo-aeroporto-v2.component.scss']
})
export class ComboAeroportoV2Component implements OnInit {

  @Input("label") label = "Escolha o Aeroporto:";
  @Input("value") value = null;
  @Input("text") text   = null;
  @ViewChild("select") select:ElementRef; 
  @Output("changeValue") changeValue = new EventEmitter();
  public comboCtrl        = new UntypedFormControl();
  public comboFilter:Observable<any>;
  public loader         = false;
  public response       = {
    data: [],
    status: null,
    message: ""
  };
  public paramsAtributos = {
    text: "text",
    value: "value"
  }
  public data = [];
  
  constructor(
    private api:ApiService,
    private app: AppService
  ){ }
  
  /**
   * 
   * Retorna os dados para o ComboBox
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().aeroporto.combo().subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.data            = response.data;
          this.response.status = 1;
          setTimeout(() => {
            $(this.select.nativeElement).select2("open");
          },100);
                  
        }else{

          this.response = {
            data: [],
            status: 500,
            message: response.data
          }

        }  

      },(response) => {

        this.loader = false;

        let error     = this.app.formatError(response);
        this.response = {
          data: [],
          status: 500, 
          message: error.message
        };

      });

    }catch(e){

      this.loader = false;

    }

  }
   /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null;

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["value"]){

        data = this.data[index];
        break;

      }
      
    }

    return data;

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    try{
      
      let item = this._getDataItem();
         
      if(item != null){
        this.changeValue.emit(item);
      }

    }catch(e){
      
    }

  }
  /**
  * 
  * Get data
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      $(this.select.nativeElement).select2("close");
      this.getData();
    }

  }
  /***
   * 
   * Init select
   * 
   */
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      //this returns all the selected item
      let value = $(this).val();        

      self.value = value;
      self._change();  
  
    });

  }
  /**
   * 
   * Inicializando as Funções
   * 
   */
  ngOnInit(){

  }
  ngAfterViewInit(): void {
    this.initSelect();
  }

}
