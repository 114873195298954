import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { DataService } from 'src/app/services/data.service';
import { DmCardAddModal } from '../../modal/dm-card-add-modal/dm-card-add-modal.component';

@Component({
  selector: 'btn-add-cart',
  templateUrl: './btn-add-cart.component.html',
  styleUrls: ['./btn-add-cart.component.scss']
})
export class BtnAddCartComponent implements OnInit {

  @Input("macroRegiao") macroRegiao           = null;
  @Input("configuracaoLoja") configuracaoLoja = null;
  @Input("categoria") categoria = null;    
  @Input("servico") servico = null;
  @Input("horarios") horarios = null;
  @Input("idCart") idCart = null;
  @Input("valor") valor = null;
  @Input("emailSugestao") emailSugestao = null;
  @Input("data") data = null;
  @Input("email") email = null;
  public color = null;
  public configuracao = null;
  @Input("type") type = 1;

  constructor(
    private app: AppService,
    private dialog: MatDialog,
    private cart: CartService,
    public dataService: DataService
  ){}


  /**
   * 
   * 
   * 
   */
  open(){

    this.dialog.open(DmCardAddModal,{
      width: "800px",
      data: {
        macroRegiao: this.macroRegiao,
        configuracaoLoja: this.configuracaoLoja,
        categoria: this.categoria,
        servico: this.servico,
        horarios: this.horarios,
        idCart: this.idCart,
        valor: this.valor,
        emailSugestao: this.emailSugestao,
        email: this.email,
        cartService: this.cart,
        type: this.type
      }
    });

  }
  /**
   * 
   * Set color
   * 
   */
  setColor(){

    if(this.configuracao != null){

      this.color = this.configuracao.cor_2;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.configuracao = this.dataService.getConfiguracao();
    this.setColor();
  }

}
