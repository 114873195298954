<div class="module-product" id="tour">
      <div class="section-box">
          <div class="container">
              <div class="content">
                  <div class="box box-description">
                      <h2 class="wow slideInUp">{{data.titulo}}</h2>
                      <article class="wow slideInLeft description" [innerHtml]="produto.descricao"></article> 
                      <div class="content-button">
                        <btn-reserva [produto]="produto" [id]="produto.id" class="wow slideInLeft"></btn-reserva>
                      </div>
                  </div>
                  <div class="box">
                      <div class="video wow slideInRight">
                        <produto-video [url]="produto.link_video"></produto-video>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="section-box">
          <div class="container">
              <div class="items-info">
                  <div class="item wow slideInUp">
                      <div class="icon">
                          <i class="fa-solid fa-clock"></i>
                      </div>
                      <div class="text">
                          <span>Duração</span>
                          <strong>{{produto.duracao | formatDuracao}}</strong>
                      </div>
                  </div>
                  <div class="item wow slideInDown">
                      <div class="icon">
                          <i class="fa-solid fa-location-dot"></i>
                      </div>
                      <div class="text">
                          <span>Localização</span>
                          <strong>Região dos vinhos/RS</strong>
                      </div>
                  </div>
                  <div class="item wow slideInUp">
                      <div class="icon">
                          <i class="fa-solid fa-car"></i>
                      </div>
                      <div class="text">
                          <span>Transporte</span>
                          <strong>Ida/volta Gramado Receptivo</strong>
                      </div>
                  </div>
                  <div class="item slideInDown">
                      <div class="icon">
                          <i class="fa-solid fa-hotel"></i>
                      </div>
                      <div class="text">
                          <span>Recolhe</span>
                          <strong>Hotéis/Pousadas Gramado/Canela</strong>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="section-box">
          <div class="container">
              <div class="row">
                  <div class="col-md-7">
                    <div class="textos">
                        <div class="item" *ngFor="let t of textos">
                            <h2 class="wow slideInUp">{{t.titulo}}</h2>
                            <article class="description" [innerHtml]="t.texto | safeHtml"></article>
                        </div>
                    </div>
                    <div class="caracteristica" *ngIf="caracteristica.length > 0">
                      <h2 class="wow slideInUp">Incluso e Não Incluso</h2>
                      <div class="items-list">
                          <ul class="incluso">
                              <li *ngFor="let i of incluso">
                                  <div class="icon">
                                      <i class="fa-solid fa-check"></i>
                                  </div>
                                  <span>{{i.descricao}}</span>
                              </li>
                          </ul>
                          <div class="linha"></div>
                          <ul class="nao-incluso">
                              <li *ngFor="let i of naoIncluso">
                                  <div class="icon">
                                      <i class="fa-solid fa-xmark"></i>
                                  </div>
                                  <span>{{i.descricao}}</span>
                              </li>
                          </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5"> 
                      <div class="box-valor">
                          <div class="valor wow slideInRight">
                              <span>A partir de</span>
                              <strong class="total">{{produto.menor_tarifa | currency: ' R$ '}}</strong>
                              <span>por pessoa</span>
                          </div>
                          <div class="days wow slideInUp">
                              <div class="icon">
                                  <i class="fa-solid fa-calendar-days"></i>
                              </div>
                              <div class="text">
                                  <strong>Dias que ocorre:</strong>
                                  <span>{{dias | formatDias}}</span>
                              </div>
                          </div>
                          <btn-reserva [produto]="produto" [id]="produto.id" class="btn-reserva-content  slideInLeft"></btn-reserva>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="section-box section-gallery">
          <div class="container">
              <h2>Galeria de Fotos</h2>
              <produto-galeria-two [photos]="fotos"></produto-galeria-two>
              <div class="content-button center-flex">
                <btn-reserva [produto]="produto" [id]="produto.id" class="wow slideInLeft"></btn-reserva>
              </div>
          </div>
      </section>
  </div>
  