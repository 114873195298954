<div class="dm-modal">
      <div class="dm-modal-header">
          <span>{{title}}</span>
          <div class="icon" (click)="dialog.close()">
              <img src="images/icons/close.svg" alt="Close" width="25px" />
          </div>
      </div>
      <div class="dm-modal-content">
          <article [innerHtml]="texto | safeHtml"></article>
      </div>
  </div>
  