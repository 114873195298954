<div class="modulo modulo-contato" *ngIf="template == null">
  <h3 class="title-h3">{{data.titulo}}</h3>
  <ul class="menu-list menu-contact">
    <li>
      <a href="mailto:{{data.params.email}}" title="E-mail">
        <i class="fa-solid fa-envelope"></i>
        <span>{{data.params.email}}</span>
      </a> 
    </li>
    <li>
      <a href="tel:{{data.params.whatsapp}}" title="WhatsApp">
        <i class="fa-brands fa-whatsapp"></i>
        <span>{{data.params.whatsapp}}</span>
      </a>    
    </li>
  </ul>
</div>
<div class="modulo modulo-contato" *ngIf="template == 'relacionamento'">
  <div class="box-relacionamento">
    <div class="icon">
        <i class="fa-brands fa-whatsapp"></i>
    </div>    
    <strong>CENTRAL DE RELACIONAMENTO</strong>
    <div class="whatsapp-number">
        <strong>WHATSAPP</strong>
        <a href="https://api.whatsapp.com/send?phone={{numero}}" target="_blank">
          {{data.params.whatsapp}}
        </a>    
    </div>    
 </div>
</div>

