import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ServicoService } from '../../../services/servico.service';
import { AppService } from '../../../services/app.service';
import { StorageService } from '../../../services/storage.service';
import { GtagService } from '../../../services/gtag.service';
import { FbService } from '../../../services/fb.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DmCardEmailModal } from '../modal/dm-card-email/dm-card-email.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'dm-cart-add',
  templateUrl: './dm-cart-add.component.html',
  styleUrls: ['./dm-cart-add.component.scss']
})
export class DmCartAdd implements OnInit,OnChanges {
  
  @Input("dialog") _dialog:MatDialogRef<any>  = null;
  @Input("servico") servico                   = null; 
  @Input("categoria") categoria               = null; 
  @Input("macroRegiao") macroRegiao           = [];  
  @Input("configuracaoLoja") configuracaoLoja = null; 
  @Input("ultimoServicoInserido") ultimoServicoInserido = null; 
  @Input("horarios") horarios                 = [];  
  @Input("idCart") idCart = null; 
  @Input("isPrime") isPrime = false; 
  @Input("email") email = null;
  @Input("emailSugestao") emailSugestao = null;  
  @ViewChild("dateInput") dateInput:ElementRef;
  @Input("cartService") cartService = null;
  @Input("lp") lp:any = null;
  public lpData = null;
  public step                                 = 1;
  public loader                               = false;
  public loaderTipoServico                    = false;
  public dataTipos                            = {
    data: null,
    countTipo: null,
    contemRegular: false,
    contemPrivativo: false,
    status: null,
    success: false
  };
  public messageLoader = "";
  public dataItem:any  = {}
  public message       = {
    data: "",
    show: false,
    class: ""
  }
  public disponibilidadeArray = null;
  public messageAdd = {
    show: false 
  }
  public pedirDadosVoo    = false;
  public subscribeMessage = null;
  public hotelFixo = {
    nome_hotel: "INDEFINIDO GRAMADO",
    hotel_id: 21,
    macro_regiao_id: 1,
    macro_regiao: "REGIÃO DAS HORTÊNCIAS",
    zona_regiao_hotel_id: 1,
    zona_regiao_hotel: "ZONA 1",
    cidade_id: 1924
  }
  public loaderCarrinho      = false;
  public frequenciaApartirDe = null;
  public dataHorariosIn      = [];
  public dataHorariosOut     = [];
  public comboTurno:any      = [];
  public dataOrigem:any      = null;
  public dataDestino:any     = null;

  constructor(
    private api: ApiService,
    private _servico: ServicoService,
    private app: AppService, 
    private storage: StorageService,
    private gtag: GtagService,
    private fb: FbService,
    private dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _data: DataService,
    private router: Router,
    public data: DataService
  ){

    this.ultimoServicoInserido = this.storage.getUltimolServico();
    this.lp = this.data.getLp();

  }
  /**
   * 
   * To route
   * 
   */
  _toRoute(data){

    if(this.lp == null){
      this.router.navigateByUrl(data); 
    }else{

      if(data != "/servicos"){
        this.router.navigateByUrl("/lp/"+this.lp.apelido+data); 
      }else{
        this.router.navigateByUrl("/lp/"+this.lp.apelido); 
      }

    }

  }
  /**
  * 
  * Set step
  * 
  */
  setStep(step){

    this.step = step;
    if(this.step == 4 && this.servico.vale_presente){
      this.next();
    }

  }
  /**
   * 
   * @param data 
   * 
   * 
   */
   changeTurno(data){

    this.dataItem.id_turno = data.value;
    this.dataItem.turno    = data.text;

  }
  /**
   * 
   * Valida a disponibilidade
   * 
   *  
   **/ 
  validarDisponibilidade(){

    try{

      if(this.dataItem.tipo_servico_id == 1){ 

        this.loader = true;
        this.subscribeMessage = "Validando. Aguarde!";

        this.api.manage().validarDisponibilidade(
          this.dataItem.servico_id,
          this.dataItem.data_utilizacao,
          this.dataItem.tipo_servico_id,
          this.dataItem.qtd_adt,
          this.dataItem.qtd_chd,
          this.dataItem.qtd_inf,
          this.dataItem.qtd_snr
        ).subscribe(response => {

          this.loader = false;
          
          if(response.status == 1){ 

            if(response.data === "null" || response.data === null){

              this.addCart(false);

            }else{

              this.subscribeMessage = "";

              for(let index = 0; index < response.data.length; index++) {

                this.subscribeMessage += response.data[index]+"<br>"; 
                
              }
              
            }

          }else{

            this.subscribeMessage = response.data;

          }

        },(response) => {

          this.subscribeMessage = "";
          this.loader           = false;
          

        });

      }else{

        this.addCart(false);

      }

    }catch(e){
      this.subscribeMessage = "";
      this.loader           = false;
      this.app.info(e.message);  
    }

  }
  /**
   * 
   * Next Step
   * 
   */
   next(validarDisponibilidade=false){ 

    this.subscribeMessage = null;
    let validation        = {data: "",status:true};

    if(!this.servico.vale_presente){
      if(validarDisponibilidade){ 
        this.validarDisponibilidade();
        return false;
      }
    }  
    if(this.step == 1){
      validation = this._servico.validStep1(this.dataItem);
    }
    if(this.step == 2){
      validation = this._servico.validStep2(this.dataItem);
    }
    if(this.step == 3){  

      if(this.dataItem.tipo_parametro_servico == 1 || this.dataItem.tipo_parametro_servico == 2 || this.dataItem.tipo_parametro_servico == 3){
        validation = this._servico.validTrajeto(this.dataItem);
      }else{
        validation = this._servico.validStep3(this.dataItem);
      }

    }
    if(this.step == 4){
      validation = this._servico.validQtdPax(this.dataItem); // valida qtd paxs
    }
    if(this.step == 5){
      validation = this._servico.validStep4(this.dataItem); // valida a data
    }
    if(this.step == 6 && this.pedirDadosVoo){
      if(!this.dataItem.informar_horario){
        validation = this._servico.validDadosVoo(this.dataItem);
      }else{
        validation = this._servico.validHorario(this.dataItem);
      }
    }
    if(validation.status){
      this.step++; 
      if(this.step == 3 && this.servico.hotel_fixo && (!this.dataItem.require_destino && !this.dataItem.require_origem)){
        this.step++;
      }
      if(this.step == 6 && !this.pedirDadosVoo){
        this.step++;
      }
    }else{
      this.app.info(validation.data,"danger");
    }  

    if(this.step == 2){
      if(this.dataTipos.status == null){
        this.getTiposServico();
      }  
    }

  }
  /* 
  * Prev Step
  * 
  */
 prev(){
   this.step--;

   if(this.step == 6 && !this.pedirDadosVoo){
     this.step--;
     //if(this.servico.hotel_fixo){
       //this.step--;
     //}
   }

 }
  /**
   * 
   * Show
   * 
   * @param message 
   * @param classCustom 
   * 
   */
  showModalMessage(message,classCustom="alert alert-info"){

    this.message.data  = message;
    this.message.class = classCustom;
    this.message.show  = true;

  }
   /**
   * 
   * Tipo de Serviço
   * 
   */
  getTiposServico(){

    try{
      this.loader  = true;
      this.messageLoader      = "Carregando tipos de serviço. Aguarde!";

      this.api.manage().listaTiposServicosById(this.servico.manage_id,this.dataItem.macro_regiao_id).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          
          if(response.data.length > 0){
            
            if(typeof(response.data[0].frequenciaApartirDe) != "undefined"){

              this.frequenciaApartirDe = response.data[0].frequenciaApartirDe.length > 0 ? response.data[0].frequenciaApartirDe : null;

            }
            if(typeof(response.data[0].tipoParametroServico) != "undefined"){

              this.dataItem.tipo_parametro_servico = response.data[0].tipoParametroServico == "0" ? 0 : parseInt(response.data[0].tipoParametroServico);

              this.dataItem.parametro_servico      = response.data[0].parametroServico;   

              if(this.dataItem.tipo_parametro_servico == 1 || this.dataItem.tipo_parametro_servico == 2 || this.dataItem.tipo_parametro_servico == 3){
                this.dataItem.require_destino        = true;  
                this.dataItem.require_origem         = true;  
              }else{
                this.dataItem.require_destino        = false;  
                this.dataItem.require_origem         = false; 
              }

            }   
            /*if(typeof(response.data[0].origemRestrita) != "undefined"){

              this.dataItem.require_origem = response.data[0].origemRestrita === "false" || response.data[0].origemRestrita === false ? false : true;

            }
            if(typeof(response.data[0].destinoRestrito) != "undefined"){

              this.dataItem.require_destino = response.data[0].destinoRestrito === "false" || response.data[0].destinoRestrito === false ? false : true; 

            }*/

            let data = this._servico.agrupaPorTipoServico(response.data);
            
            this.dataTipos = {
              data: data.response,
              countTipo: data.countTipo,
              contemRegular: data.contemRegular,
              contemPrivativo: data.contemPrivativo, 
              status: 1,
              success: data.response.length > 0 ? true : false
            };
            this.checkTipoServico();
          
          }else{

            this.app.info("Este serviço não contêm disponibilidade.");

            if(this._dialog != null){

              this._dialog.close();  

            }

          }

        }else{
          
          this.app.info("Não foi possível carregar a disponibilidade. Se o problema persistir, entre em contato com a nossa equipe.");
          
          if(this._dialog != null){
            this._dialog.close();  
          }
           
        }
        
      },(response) => {   

        this.loaderTipoServico = false;
        this.app.info("Houve um erro ao carregar os tipos de serviços. Tente novamente.");

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Checa os tipos de serviço
   * 
   * 
   */
  checkTipoServico(){

    if(this.dataTipos.countTipo == 1){

      this.dataItem.tipo_servico_id = this.dataTipos.data[0].idTipoServico;
      this.dataItem.tipo_servico    = this.dataTipos.data[0].tipoServico;

      if(this.dataItem.tipo_servico_id == 1){
        if(this.dataItem.hotel_id == null){
          if(this.servico.hotel_fixo){
            this.setStep(4);
            return false;
          }
          this.setStep(3);
        }else{
          if(this.servico.pacote_in_out){
            this.setStep(4);
          }else{
            this.setStep(4); 
          }
        }
      }

    }else{

      if(this.dataTipos.contemRegular){ 
        this.dataItem.tipo_servico_id = 1;
        this.dataItem.tipo_servico    = "Regular";
      }else if(this.dataTipos.contemPrivativo){
        this.dataItem.tipo_servico_id = 2;
        this.dataItem.tipo_servico    = "Privativo";
      }
      
    }

  }
  /**
   * 
   * Change Horário
   * 
   */
  _changeHorarioIn(value){

    this.dataItem.horario_in = value; 

  }
  /**
   * 
   * Change Horário
   * 
   */
  _changeHorarioOut(value){

    this.dataItem.horario_out = value;  

  }
  /**
   * 
   * Iniciliza o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      produto_id: this.servico.id,  
      nome: this.servico.nome,
      email: this.email,
      servico_id: this.servico.manage_id, 
      tipo_servico: null,
      tipo_servico_id: null,
      tipo_transporte: null,
      tipo_transporte_id: null,
      tipo_contratacao: null, 
      parametro_servico_id: this.servico.produto_parametro_servico_id,
      macro_regiao_id: null,
      macro_regiao: null,
      zona_regiao_hotel_id: null,
      zona_regiao_hotel: null,
      cidade_id: null,
      data_utilizacao: null,
      valor_adt: 0,
      valor_snr: 0,
      valor_chd: 0,
      valor_inf: 0,
      valor_servico: 0,
      nome_hotel: null,
      hotel_id: null,
      codigo_moeda: "",
      qtd_adt: 0, 
      qtd_chd: 0,
      qtd_inf: 0,
      qtd_snr: 0,
      passageiros: [],
      voo: "",
      hora_voo: "",
      cia_aerea_text: "",
      cia_aerea_codigo: "",
      aeroporto: "",
      nome_cia_aerea: "",
      pedir_dados_voo: false,
      lotacao_maxima: 0,
      solicitar_endereco: false,
      endereco: "",
      hotel_fixo: false,
      vale_presente: this.servico.vale_presente,
      vale_presente_valor: 0,
      pacote_in_out: this.servico.pacote_in_out,
      data_chegada: "",
      aeroporto_chegada: "",
      cia_chegada_text: "",
      cia_chegada_codigo: "",
      voo_chegada: "",
      hora_chegada: "",
      data_partida: "",
      aeroporto_partida: "",
      cia_partida_text: "",
      cia_partida_codigo: "",
      voo_partida: "",
      hora_partida: "",  
      informar_horario: this.servico.informar_horarios,
      horario_in: "",
      horario_out: "",
      token: null,
      id_local_pick_up: null,
      id_local_drop_off: null,
      local_pick_up: "",
      local_drop_off: "",
      id_turno: 0,
      turno: null,
      tipo_parametro_servico: null,
      parametro_servico: null,
      tipo_local_origem: null, 
      tipo_local_destino: null,
      require_origem: false,
      require_destino: false,
      lp_id: this.lp == null ? null : this.lp.id
    }

    if(this.dataItem.informar_horario){ 

      //this.dataHorariosIn  = this.formatarHorarios(this.horarios,1);
      //this.dataHorariosOut = this.formatarHorarios(this.horarios,2); 

    }
    if(this.servico.pacote_in_out){
      this.pedirDadosVoo = true;  
    }
    if(this.servico.hotel_fixo == true){
      this.dataItem.hotel_id              = this.configuracaoLoja.hotel_id;
      this.dataItem.nome_hotel            = this.configuracaoLoja.nome_hotel; 
      this.dataItem.macro_regiao_id       = this.configuracaoLoja.macro_regiao_id; 
      this.dataItem.macro_regiao          = this.configuracaoLoja.macro_regiao;
      this.dataItem.zona_regiao_hotel     = this.configuracaoLoja.zona_regiao_hotel;
      this.dataItem.zona_regiao_hotel_id  = this.configuracaoLoja.zona_regiao_hotel_id;
      this.dataItem.cidade_id             = this.configuracaoLoja.hotel_cidade_id;
      this.dataItem.hotel_fixo            = true; 
    }
    if(this.ultimoServicoInserido != null){
      this.dataItem.hotel_id              = this.ultimoServicoInserido.hotel_id; 
      this.dataItem.nome_hotel            = this.ultimoServicoInserido.nome_hotel; 
      this.dataItem.macro_regiao_id       = this.ultimoServicoInserido.macro_regiao_id; 
      this.dataItem.macro_regiao          = this.ultimoServicoInserido.macro_regiao;
      this.dataItem.zona_regiao_hotel     = this.ultimoServicoInserido.zona_regiao_hotel;
      this.dataItem.zona_regiao_hotel_id  = this.ultimoServicoInserido.zona_regiao_hotel_id;
      this.dataItem.cidade_id             = this.ultimoServicoInserido.cidade_id;
      this.dataItem.endereco              = this.ultimoServicoInserido.endereco;
      this.dataItem.solicitar_endereco    = this.ultimoServicoInserido.solicitar_endereco;
    }    
 

  }
  /**
   * 
   * Formatar horários
   * 
   */
  formatarHorarios(data,tipo){

    let out = [];

    for (let index = 0; index < data.length; index++) {
      
      if(tipo == data[index].tipo){
        out.push(data[index].horario);   
      }   
      
    }

    return out;

  }
  /**
   * 
   * Change Hora Partida
   * 
   */
   changeHoraVoo(value){

    this.dataItem.hora_voo = value;

  }
  /**
   * 
   * Change Hora Partida
   * 
   */
  changeHoraPartida(value){

    this.dataItem.hora_partida = value;

  }
  /**
   * 
   * Change Hora Partida
   * 
   */
  changeHoraChegada(value){ 
    
    this.dataItem.hora_chegada = value;

  }
  /**
   * 
   * Change Voo In
   * 
   */
  changeVoo(value){

    this.dataItem.voo = value;

  }
  /**
   * 
   * Change Voo In
   * 
   */
  changeVooIn(value){

    this.dataItem.voo_chegada = value;

  }
  /**
   * 
   * Change Voo Out
   * 
   */
  changeVooOut(value){

    this.dataItem.voo_partida = value;

  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeCiaIn(value){

    this.dataItem.cia_chegada_text   = value.text;
    this.dataItem.cia_chegada_codigo = value.codigo

  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeCiaOut(value){

    this.dataItem.cia_partida_text   = value.text;
    this.dataItem.cia_partida_codigo = value.codigo

  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeAeroportoIn(data){

    this.dataItem.aeroporto_chegada = data.text;

  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeAeroportoOut(data){

    this.dataItem.aeroporto_partida = data.text;

  }
  /**
   * 
   * Change Cia Aerea
   * 
   * @param value 
   * 
   */
  changeCia(data){

    this.dataItem.cia_aerea_text   = data.text;
    this.dataItem.cia_aerea_codigo = data.codigo;
  }
  /**
   * 
   * Change Aeroporto
   * 
   * @param value 
   * 
   */
  changeAeroporto(data){

    this.dataItem.aeroporto = data.text;

  }
  /**
   * 
   * Change a Região
   * 
   */
  changeMacroRegiao(value){

    this.dataItem.macro_regiao_id = value.idMacroRegiao;
    this.dataItem.macro_regiao    = value.macroRegiao;

  }
  /**
   * 
   * Change a Região
   * 
   */
  changeHotel(value){

    this.dataItem.nome_hotel = value.hotelPousada;
    this.dataItem.hotel_id   = value.idHotelPousada;

    if(value.idZonaRegiaoHotel.length > 0){
      this.dataItem.zona_regiao_hotel_id = value.idZonaRegiaoHotel;
    }
    if(value.zonaRegiaoHotel.length > 0){
      this.dataItem.zona_regiao_hotel = value.zonaRegiaoHotel;
    }
    if(value.idCidadeMunicipio.length > 0){
      this.dataItem.cidade_id = value.idCidadeMunicipio;
    }
    if(value.idCidadeMunicipio.length > 0){
      this.dataItem.cidade_id = value.idCidadeMunicipio;
    }  
    if(value.solicitarEndereco === "true" || value.solicitarEndereco === true){
      this.dataItem.solicitar_endereco = true;
    }else{
      this.dataItem.solicitar_endereco = false;
    }

  }
  /**
   * 
   * Change Data Partida
   * 
   */
  changeDataRetorno(data){

    this.dataItem.data_partida = data; 

  }
  /**
   * 
   * Change QTDs
   * 
   * @param value 
   * 
   */
  changeQtds(value){

    this.dataItem.qtd_adt = value.qtd_adt;
    this.dataItem.qtd_chd = value.qtd_chd;
    this.dataItem.qtd_inf = value.qtd_inf;
    this.dataItem.qtd_snr = value.qtd_snr;

  }
  /**
   * 
   * Change Lista de Passgaeiros
   * 
   * @param value 
   * 
   */
  changeListaPassageiros(passageiros){

    this.dataItem.passageiros = passageiros;

  }
  /**
   * 
   * Change Data Serviço
   * 
   */
  changeDataServico(value){
  
    this.dataItem.data_utilizacao = value.data;
    this.disponibilidadeArray     = value.disponibilidadeArray;

    let tarifas:any = this._servico.getTarifasByDate2(
      this.dataItem.data_utilizacao,
      this.dataItem.zona_regiao_hotel_id,
      this.dataItem.cidade_id,
      this.disponibilidadeArray
    );
        
    this.dataItem.valor_adt          = tarifas.valor_adt; 
    this.dataItem.valor_chd          = tarifas.valor_chd; 
    this.dataItem.valor_snr          = tarifas.valor_snr; 
    this.dataItem.valor_inf          = tarifas.valor_inf; 
    this.dataItem.valor_servico      = tarifas.valor_servico; 
    this.dataItem.codigo_moeda       = tarifas.codigo_moeda; 
    this.dataItem.tipo_contratacao   = tarifas.tipo_contratacao; 
    this.dataItem.id_turno           = tarifas.id_turno;
    this.dataItem.turno              = tarifas.turno;

    if(this.dataItem.id_turno != 0){
      this.comboTurno = this._servico.getTurnos(this.disponibilidadeArray,this.dataItem.data_utilizacao);
    }
     
  }
  /**
   * 
   * Return Recaptcha
   * 
   */
  getToken(){

    try{
      
      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {

       this.dataItem.token = token;

      },(response) => {  

        this.dataItem.token = null;

      });
    
    }catch(e){

      this.dataItem.token = null;

    }

  }
  /**
   * 
   * Inseri no Carrinho
   * 
   */
  addCart(validarDisponibilidade=true){

    try{

      
       if(this.configuracaoLoja.solicitar_email_pre_venda && this.dataItem.email == null){
          
          this.dialog.open(DmCardEmailModal,{
            width: "400px",
            height: "auto",   
            disableClose: true, 
            data: {   
              email: this.emailSugestao,
              self: this
            } 
          });  
          return false;
          
        }
        if(this.dataItem.qtd_adt === 0 && this.dataItem.qtd_snr === 0 && this.dataItem.qtd_chd === 0){
          this.app.info("- Informe a quantidade de passageiros.","danger");
          return false;
        }
        let validation        = this._servico.validDadosPassageiros(this.dataItem.passageiros);
        this.subscribeMessage = null;    

        if(!validation.status){ 
          this.app.info(validation.data,"danger");
          return false;
        }
      this.loader         = true;
      this.messageLoader  = "Inserindo no carrinho. Aguarde!";

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {
  
        this.dataItem.token = token;   

        this.api.loja().carrinho().store(this.dataItem).subscribe(response => {

          this.loader    = false;

          switch(response.status){
            case 1:
                              
              if(!this.dataItem.hotel_fixo){
                this.storage.setUltimoServico(this.dataItem);
              }
              this._data.removeConfirmacaoEmail();;   
              this.cartService.emitirUpdateTotalCart.emit(response.data.total_cart);
              this.cartService.emitirAddItem.emit(response.data.item);

              let valor  = null;
              if(this.dataItem.servico_tipo_id == 2){
                valor = this.dataItem.valor_servico;
              }else{
                valor = ((this.dataItem.valor_adt*this.dataItem.qtd_adt)+(this.dataItem.valor_snr*this.dataItem.qtd_snr)+(this.dataItem.valor_chd*this.dataItem.qtd_chd));
              }
              this.gtag.addCart({ 
                id: this.servico.id,
                nome: this.dataItem.nome, 
                //categoria: this.categoria.titulo,
                valor: parseFloat(valor)
              });
              this.fb.addCartSingle({ 
                id: this.servico.id,
                nome: this.dataItem.nome, 
                //categoria: this.categoria.titulo,
                valor: parseFloat(valor)
              });  

              this.router.navigateByUrl("/carrinho");
              this.setMacroRegiao();
              this.checkTipoServico(); 

              //this.step = 8; 
                     
            break;
            case 2:

              let message = "";

              for (let index = 0; index < response.data.length; index++) {
                
                message += "<p>"+response.data[index]+"</p>";
                
              }  

              this.app.info(message);  

            break;
            case 422:
                
              let messages = ""

              if(typeof(response.data.item) != "undefined"){
                if(response.data.item.length != 0){
                  messages += "<span>" 
                  messages += this.app.getErrors([response.data.item])
                  messages += "</span><br>"
                } 
              }  
    
              messages += "<span>";
                
              if(typeof(response.data.paxs) != "undefined"){
                if(response.data.paxs.length > 0){
                  messages += this.app.getErrors(response.data.paxs,true)
                }  
              }  
              messages += "</span>";

              this.app.info(messages,"danger");
            break;
            default:
              
              this.app.info("Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.<br> <strong>Detalhe:</strong> <br>"+response.data,"danger");  
            
            break;
          
          }

        },(response) => {

          this.loader         = false; 
          let error           = this.api.formatError(response);
          let messages        = error.message; 
          this.app.info(messages,"danger"); 

        });
      
      },(response) => { 

        this.loader   = false;
        this.app.info("Token de validação inválido. Recarregue a página para continuar.");

      });

    }catch(e){

      this.loader = false;  
      this.app.info(e.message); 

    }

  }
  /**
   * 
   * Seta a Macro Região
   * 
   */
  setMacroRegiao(){

    if(this.macroRegiao.length > 0){
      
      for(let index = 0; index < this.macroRegiao.length; index++) {
        
        if(this.macroRegiao[index].padrao === "true" || this.macroRegiao[index].padrao === true){

          this.dataItem.macro_regiao_id = this.macroRegiao[index].id;
          this.dataItem.macro_regiao    = this.macroRegiao[index].descricao;

        }
        
      }
      if(this.dataItem.macro_regiao_id != null){
        this.setStep(2);
        if(this.dataTipos.status == null){
          setTimeout(() => {
            this.getTiposServico();
          },500);
        }  
      }

    }

  }
  /***
   * 
   * Change Step
   * 
   */
  _changeStep(step){

    this.step = step;

  }
  /**
  * 
  * Cria o calendário
  * 
  */
 createCalendar(){
   
  let self = this;

  if(typeof(this.dateInput.nativeElement) == "undefined"){
    //return false;
  }

  $(this.dateInput.nativeElement).datepicker({
    dateFormat: "dd/mm/yy", 
    minDate: this.dataItem.data_utilizacao,
    changeMonth: false,
    changeYear: false,
    showOn: "button",
    buttonImage: "/images/icons/date.svg",  
    buttonImageOnly: true,
    dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
    monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
    onSelect: (value)=>{ 
      
      let data                   = $(this.dateInput.nativeElement).val();
      self.dataItem.data_partida = data;

    }

  }); 
 
  }
  /***
   * 
   * Change local pick up
   * 
   */
  changeLocalPickUp(data){  

    this.dataItem.id_local_pick_up  = data.idHotelPousada;
    this.dataItem.local_pick_up     = data.hotelPousada;
    this.dataItem.tipo_local_origem = data.tipo;
    this.dataOrigem                 = data;

    if(data.tipo != "AEROPORTO"){

      this.setHotelByTipo(data,data.tipo);

    }else if(data.tipo == "AEROPORTO" && this.dataDestino == null){

      this.dataItem.nome_hotel = null;
      this.dataItem.hotel_id   = null;

    }else{

      if(this.dataDestino != null){
        if(this.dataDestino.tipo != "AEROPORTO"){
          this.setHotelByTipo(this.dataDestino,this.dataDestino.tipo); 
        }
      }

    }

    if(this.dataItem.tipo_local_origem == "AEROPORTO" && this.dataItem.tipo_local_destino == "AEROPORTO"){

      this.dataItem.nome_hotel = null;
      this.dataItem.hotel_id   = null;

    }

    this.setPedirDadosVoo();

  }
  /**
   * 
   * Set pedir dados voo
   * 
   */
  setPedirDadosVoo(){

    if(this.dataItem.tipo_local_origem == "AEROPORTO" || this.dataItem.tipo_local_destino == "AEROPORTO"){

      this.pedirDadosVoo            = true;
      this.dataItem.pedir_dados_voo = true;

    }else{
      
      
      this.pedirDadosVoo            = false;
      this.dataItem.pedir_dados_voo = false;

    }
    
    if(this.dataItem.tipo_local_origem == "AEROPORTO" && this.dataItem.tipo_local_destino == "AEROPORTO"){
      this.dataItem.pacote_in_out = true;
    }else{
      this.dataItem.pacote_in_out = false;  
    }

  }
  /**
   * 
   * Change local drop off
   * 
   */
  changeLocalDropOff(data){

    this.dataItem.id_local_drop_off  = data.idHotelPousada;
    this.dataItem.local_drop_off     = data.hotelPousada;
    this.dataItem.tipo_local_destino = data.tipo;
    this.dataDestino                 = data;

    if(this.dataOrigem == null){
      this.setHotelByTipo(data,data.tipo);
    }else if(this.dataOrigem.tipo == "AEROPORTO"){
      this.setHotelByTipo(data,data.tipo);
    }
    
    if(this.dataItem.tipo_local_origem == "AEROPORTO" && this.dataItem.tipo_local_destino == "AEROPORTO"){

      this.dataItem.nome_hotel = null;
      this.dataItem.hotel_id   = null;

    }
    
    this.setPedirDadosVoo();    

  }
  /**
   * 
   * Tipo
   * 
   */
   setHotelByTipo(data,tipo){


    if(tipo != "AEROPORTO"){   
        
        this.dataItem.hotel_id    = data.idHotelPousada;
        this.dataItem.nome_hotel  = data.hotelPousada; 

        if(data.idZonaRegiaoHotel.length > 0){
          this.dataItem.zona_regiao_hotel_id = data.idZonaRegiaoHotel;
        }
        if(data.zonaRegiaoHotel.length > 0){
          this.dataItem.zona_regiao_hotel = data.zonaRegiaoHotel;
        }
        if(data.idCidadeMunicipio.length > 0){
          this.dataItem.cidade_id = data.idCidadeMunicipio;
        }
        if(data.idCidadeMunicipio.length > 0){
          this.dataItem.cidade_id = data.idCidadeMunicipio;
        }
        if(data.solicitarEndereco === "true" || data.solicitarEndereco === true){
          this.dataItem.solicitar_endereco = true; 
        }else{
          this.dataItem.solicitar_endereco = false;
        }

    }

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.setMacroRegiao();
  }
  /**
   * 
   * Vefifica mudanças
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any):void{}

}
