<footer>   
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-item">
                    <h3 class="title-h3">Links Úteis</h3>
                    <ul class="menu-list">
                        <li>
                            <a href="#" title="Gramado Receptivo - Políticas de Privacidade" (click)="openPoliticas()">
                                Políticas de Privacidade
                            </a> 
                        </li>
                        <li>
                            <a href="#" title="Gramado Receptivo -Termos de Uso" (click)="openTermos()">
                                Termos de Uso
                            </a> 
                        </li>
                    </ul>
                    <modulo posicao="contato"></modulo>
                </div> 
                <div class="col-md-6 col-item col-map">
                    <modulo posicao="endereco"></modulo>
                </div>
                <div class="col-md-2 col-item">
                    <modulo posicao="contato" template="relacionamento"></modulo>
                </div>  
            </div>
        </div>  
    </div> 
    <modulo posicao="rede-social"></modulo>
    <div class="bottom">
        <div class="container">
            <copyright></copyright>
        </div>  
    </div>    
</footer>    
