<div class="menu-partial {{classCustom}} template-{{template}}">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content" #content> 
        <ul>
            <li>
                <a href="#tour" title="Sobre a Maria Fumaça" (click)="_click('#tour')">
                    <div class="icon">
                        <i class="fa-solid fa-train"></i>
                    </div>
                    <span>Sobre a Maria Fumaça</span>
                </a>    
            </li>
            <li>
                <a href="#rating" title="Avaliações" (click)="_click('#rating')">
                    <div class="icon">
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <span>Avaliações</span>
                </a>    
            </li>
            <li>
                <a href="#questions" title="Dúvidas Frequentes" (click)="_click('#faq')">
                    <div class="icon">
                        <i class="fa-solid fa-circle-question"></i>
                    </div>
                    <span>Dúvidas Frequentes</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   
