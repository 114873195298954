<div class="dm-modal">
    <div class="dm-modal-title">
        <span>Informe seu e-email</span> 
        <div class="close" (click)="_close()">
            <img width="12px" defaultImage="/images/pixel/bg.png" src="/images/template/icons/close.svg" alt="close" />
        </div>    
    </div> 
    <div class="dm-modal-content">
        <form class="form-custom" (submit)="send()"> 
            <div class="form-group">
                <label>E-mail:</label>
                <input type="text" [(ngModel)]="email" name="email" />
            </div> 
            <div class="form-group email-sugestao" *ngIf="emailSugestao != null" (click)="_usar()">
                <span>Clique aqui para usar este e-mail:</span> 
                <strong>{{emailSugestao}}</strong>
            </div>   
            <div class="form-group">
                <button type="submit" class="btn-one">
                    Adicionar ao Carrinho
                </button>    
            </div>
        </form>    
    </div>    
</div>    