import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'produto-galeria-modal',
  templateUrl: './produto-galeria-modal.component.html',
  styleUrls: ['./produto-galeria-modal.component.scss']
})
export class ProdutoGaleriaModal implements OnInit {

  public fotos:any = null;
  public index:any = null;

  constructor(
    public dialog: MatDialogRef<ProdutoGaleriaModal>,
    @Inject(MAT_DIALOG_DATA) public data,
  ){ 

    this.fotos = this.data.fotos;
    this.index = this.data.index;

  }

  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close();

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    if(this.index == 0){
      this.index = this.fotos.length-1;
    }else{
      this.index--;
    }

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    if(this.index == (this.fotos.length-1)){
      this.index = 0;
    }else{
      this.index++;
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
