import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-duvida-frequente',
  templateUrl: './modulo-duvida-frequente.component.html',
  styleUrls: ['./modulo-duvida-frequente.component.scss']
})
export class ModuloDuvidaFrequenteComponent implements OnInit {

  @Input("data") data:any = null;

  constructor() { }

  /**
   * 
   * Show
   * 
   */
  _show(dataItem:any){

    dataItem.show = dataItem.show ? false : true;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
