import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'modulo-paralax',
  templateUrl: './modulo-paralax.component.html',
  styleUrls: ['./modulo-paralax.component.scss']
})
export class ModuloParalaxComponent implements OnInit {

  @Input("data") data:any = null;
  public produto:any = null;

  constructor(
    public dialog: MatDialog
  ){} 

  
  /**
   * 
   * Init
   * 
   **/
  ngOnInit():void{
    if(typeof(this.data.produto) != "undefined"){
      this.produto = this.data.produto.produto;
    }
  }

}
