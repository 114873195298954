import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from './modules/home/home.module';

const routes: Routes = [{ 
  path: '',     
  loadChildren: () => HomeModule 
},{
  path: 'minha-conta', 
  loadChildren: () => import('src/app/modules/minha-conta/perfil/perfil.module').then(m => m.PerfilModule) 
},{
  path: 'minha-conta/perfil', 
  loadChildren: () => import('src/app/modules/minha-conta/perfil/perfil.module').then(m => m.PerfilModule) 
},{
  path: 'minha-conta/pedidos', 
  loadChildren: () => import('src/app/modules/minha-conta/pedidos/pedidos.module').then(m => m.PedidosModule) 
},{
  path: 'carrinho',  
  loadChildren: () => import('src/app/modules/checkout/checkout-carrinho/checkout-carrinho.module').then(m => m.CheckoutCarrinhoModule) 
},{
  path: 'carrinho/pagamento',  
  loadChildren: () => import('src/app/modules/checkout/checkout-pagamento/checkout-pagamento.module').then(m => m.CheckoutPagamentoModule) 
},{
  path: 'carrinho/pagamento/aprovado',
  loadChildren: () => import('src/app/modules/checkout/checkout-pagamento-aprovado/checkout-pagamento-aprovado.module').then(m => m.CheckoutPagamentoAprovadoModule) 
},{
  path: 'carrinho/:id',  
  loadChildren: () => import('src/app/modules/checkout/checkout-carrinho/checkout-carrinho.module').then(m => m.CheckoutCarrinhoModule) 
},{    
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
