import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'produto-item-valor',
  templateUrl: './produto-item-valor.component.html',
  styleUrls: ['./produto-item-valor.component.scss']
})
export class ProdutoItemValorComponent implements OnInit {

  @Input("valor") valor:any            = 0;
  @Input("classCustom") classCustom = "";
  @Input("template") template       = "default";
  @Input("servico") servico         = null; 
  public valor1             = 0; 
  public valor2             = 0;
  public valorPromocao:any  = 0; 
  public valorPromocao1:any = 0;
  public valorPromocao2:any = 0;   
  public valorParcela:any   = 0; 
  public valorParcela1:any  = 0;
  public valorParcela2:any  = 0;
  public configuracao       = null;  
  public color              = null;   

  constructor(
    public dataService: DataService
  ){}

  /**
   * 
   * 
   * 
   */
  setValor(){

    let vale_presente_valor = null;
    this.valor              = this.valor === null || this.valor === "null" ? 0 : this.valor;

    if(this.servico != null){
      if(this.servico.vale_presente){
        vale_presente_valor = this.servico.vale_presente_valor;
        this.valor = vale_presente_valor;
      }
    }
    
    let number:any = vale_presente_valor != null ? vale_presente_valor.toString() : this.valor.toString();
    number = number.split(".");
    this.valor1 = number[0];

    if(typeof(number[1]) != "undefined"){

      this.valor2 = number[1];

    }

    if(this.servico.promocao){  
      let number:any               = this.servico.promocao_valor.toString();  
      number              = number.split(".");
      this.valorPromocao1 = number[0]; 
      if(typeof(number[1]) != "undefined"){          
        this.valorPromocao2 = number[1]; 
      }
    }

    if(this.servico.mostrar_parcelas){ 
      
      if(this.valorPromocao > 0){
        this.valorParcela = (this.valorPromocao/this.servico.parcelas);
      }else{
        this.valorParcela = (this.valor/this.servico.parcelas);
      }
      let number:any     = this.valorParcela.toString();

      number              = number.split(".");
      this.valorParcela1  = number[0]; 

      if(typeof(number[1]) != "undefined"){
        this.valorParcela2 = number[1].substring(0,2);      
      }

    }

  }
  /**
   * 
   * Set color
   * 
   */
   setColor(){

    if(this.configuracao != null){

      this.color = this.configuracao.cor_2;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{ 
    this.configuracao = this.dataService.getConfiguracao();
    this.setColor();
    this.setValor();
  }

}
