import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'produto-avaliacao-item',
  templateUrl: './produto-avaliacao-item.component.html',
  styleUrls: ['./produto-avaliacao-item.component.scss']
})
export class ProdutoAvaliacaoItemComponent implements OnInit {

  @Input("data") data:any                             = {};
  @ViewChild("contentDescricao") contentDescricao:ElementRef = {
    nativeElement: undefined
  };
  public more = false;
  public expand = false;

  /**
   * 
   * First Letter
   * 
   */
  firstLetter(letter:any){

    return letter.substring(0,1);

  }
  /**
   * 
   * More
   * 
   */
  _expand(){

    this.expand = this.expand ? false : true;

  }
  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit():void{
    
    setTimeout(() => {

      let height = $(this.contentDescricao.nativeElement).height();

      if(height > 110){
        this.more = true;
      }

    },200);
    

  }

}
