<div class="dm-modal">
      <div class="dm-modal-header">
          <span>{{title}}</span>
          <div class="icon" (click)="dialog.close()">
              <img src="images/icons/close.svg" alt="Close" width="25px" />
          </div>
      </div>
      <div class="dm-modal-content" *ngIf="response.status == 1">
            <div class="box box-textos" *ngIf="data.textos.length > 0">
                  <div class="item" *ngFor="let i of data.textos">
                        <h3 class="title-h3">{{i.titulo}}</h3> 
                        <article [innerHtml]="i.texto | safeHtml"></article>
                  </div>
            </div>
            <div class="box box-fotos" *ngIf="data.fotos.length > 0">
                  <h3 class="title-h3">Galeria de Fotos</h3> 
                  <produto-galeria-two [photos]="data.fotos"></produto-galeria-two>
            </div>
      </div>
      <div class="dm-modal-content" *ngIf="response.status == 500">
            <page-error [data]="response.data" (reload)="getData()"></page-error>
      </div>
</div>
<loader *ngIf="loader"></loader>
  