import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboCiaAereaV2Component } from 'src/app/components/combos/combo-cia-aerea-v2/combo-cia-aerea-v2.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ComboCiaAereaV2Component
  ],
  exports: [
    ComboCiaAereaV2Component
  ],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class ComboCiaAereaModule { }
