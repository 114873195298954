<section class="page-home">  
  <partial-header></partial-header>
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
  <modulos-destaque></modulos-destaque>
  <modulo posicao="whatsapp" template="fixo"></modulo> 
  <to-up></to-up>
</section>   

 



