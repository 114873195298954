import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'modulo-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  @Input("data") data; 
  @Input("template") template       = null; 
  @Input("classCustom") classCustom = "";
  @Input("endereco") endereco;   

  constructor(
    private sanitizer: DomSanitizer
  ){ }

  /**
   * 
   * Safe
   * 
   * 
   */
  safe(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}
