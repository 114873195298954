import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoGaleriaModal } from '../produto-galeria-modal/produto-galeria-modal.component';

declare var $:any;

@Component({
  selector: 'produto-galeria-two',
  templateUrl: './produto-galeria-two.component.html',
  styleUrls: ['./produto-galeria-two.component.scss']
})
export class ProdutoGaleriaTwoComponent implements OnInit,AfterViewInit {

  @Input("photos") photos:any       = [];
  @ViewChild("gallery") gallery:any = null;

  constructor(
    private dialog: MatDialog
  ){}
    
  /**
   * 
   * Create
   * 
   */
  open(index:any){

    this.dialog.open(
      ProdutoGaleriaModal,{
        width: "700px",
        data: {
          index: index,
          fotos: this.photos
        }
      }
    );
    
  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * init
   * 
   */
  ngAfterViewInit(): void {
    
  }


}
