<div class="produto-galeria">
    <div class="content">
        <div class="ver-galeria" *ngIf="fotos.length > 3" (click)="_openGaleria()">
            <span>Ver galeria completa</span>
        </div>    
        <div class="row">
            <div class="col-md-8 _col"> 
                <div class="capa bg-img" [ngStyle]="{'background-image': 'url(' + _fotos[0].imagem + ')'}"></div>
            </div>
            <div class="col-md-4 _col">
                <div class="img-lista bg-img" [ngStyle]="{'background-image': 'url(' + _fotos[1].imagem + ')'}"></div>
                <div class="img-lista bg-img" [ngStyle]="{'background-image': 'url(' + _fotos[2].imagem + ')'}"></div>
            </div>    
        </div> 
        <div class="ver-video" *ngIf="servico.ativar_video" (click)="_openVideo()">
            <i class="fab fa-youtube"></i>  
            <span>Assista ao vídeo<br> desta experiência</span>
        </div>    
    </div> 
    <div class="content-gallery" *ngIf="fotos.length > 3"> 
        <galeria-light [fotos]="fotos" [open]="_openGallery"></galeria-light>  
    </div>     
</div>    
